import {Component, computed, effect, ElementRef, HostListener, inject, signal,} from '@angular/core'
import {FormsModule} from '@angular/forms'
import {CustomerService} from '../../../services'
import {DotLoadingServices} from "../../../../core/services/dot-loading.Services";
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'app-search-customer',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './search-customer.component.html',
  styleUrls: ['./search-customer.component.scss']
})
export class SearchCustomerComponent {
  customerService = inject(CustomerService)
  dotLoadingServices = inject(DotLoadingServices)

  searchTerms = signal({
    customer_name: '',
    payment_terms: '',
    currency_id: '-1',
    invoice_type_id: '-1',
    customer_status: '',
    page_number: "1",
    items_per_page: "10"
  })
  filterValue = signal('')

  get customers() {
    return this.customerService.all();
  }

  selectedCustomer = computed(() => {
    return this.customerService.active();
  })

  isListVisible: boolean = false

  constructor(private elementRef: ElementRef) {
    effect(() => {
      this.dotLoadingServices.setLoading(true);
      this.customerService.find(this.searchTerms(), true)
          .pipe(finalize(() => this.dotLoadingServices.setLoading(false)))
          .subscribe({
            error: (error) => console.error(error)
          });
    },{allowSignalWrites: true});

    effect(() => {
      if (typeof this.selectedCustomer() !== 'undefined') {
        this.filterValue.set(<string>this.selectedCustomer()?.customerName)
      }
    }, {allowSignalWrites: true});
  }

  onInputChange(value: string) {
    this.searchTerms.set({
      customer_name: value,
      payment_terms: '',
      currency_id: '-1',
      invoice_type_id: '-1',
      customer_status: '',
      page_number: '1',
      items_per_page: '10'
    });
  }

  onOptionSelected(id: number) {
    this.dotLoadingServices.setLoading(true);
    const customer = this.customers.filter(option => option.customerId == id);
    this.isListVisible = false;
    this.customerService.getById(String(id), true)
        .pipe(finalize(() => this.dotLoadingServices.setLoading(false)))
        .subscribe({
          error: (error) => console.error(error)
        });
  }

  selectAll(event: MouseEvent): void {
    (event.target as HTMLInputElement).select();
  }

  @HostListener('document:click', ['$event'])
  clickOutsideDropdown(event: any) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isListVisible = false;
    }
  }
}