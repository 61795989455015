import {Component, Input} from '@angular/core';
import {
    SearchBuyerComponent,
    SearchCustomerComponent,
    SearchDepartmentComponent,
    SearchHsCodeComponent,
    SearchProductTypeComponent
} from "../../../../shared";
import {NgOptimizedImage} from '@angular/common';
import {ProductSampleService} from 'src/app/components/services';
import {FormsModule} from '@angular/forms';
import {Router} from '@angular/router';

@Component({
  selector: 'app-update-product-sample-form',
  standalone: true,
  templateUrl: './update-product-development-form.component.html',
  styleUrl: './update-product-development-form.component.scss',
  imports: [SearchDepartmentComponent,
    SearchHsCodeComponent,
    SearchCustomerComponent,
    SearchBuyerComponent,
    SearchProductTypeComponent,
    NgOptimizedImage,
    FormsModule]
})
export class UpdateProductDevelopmentFormComponent {
  constructor(private productSampleService: ProductSampleService,private router: Router

  ) { }

  hsCode = -1
  @Input() sampleId: any | undefined

  // productSample: ProductSample = {
  //   // productSampleId: -1,
  //   // productSampleCode: '',
  //   productSampleName: '',
  //   productType: {
  //     hsCodeId: -1,
  //     departmentId: -1,
  //     productTypeId: -1,
  //     productTypeCode: '',
  //     productTypeName: '',
  //   },
  //   customer: '',
  //   buyer: {
  //     buyerId: -1,
  //     buyerCode: '',
  //     buyerName: '',
  //   },
  //
  //   contactPerson: '',
  //   reference: '',
  //   sampleOwner: {
  //     department: '',
  //     employeeId: -1,
  //     employeeNo: '',
  //     employeeName: '',
  //     employeeCategory: '',
  //   },
  //   productSampleQuantity: 0,
  //   productSampleStatus: ''
  // }

  // ngOnChanges(changes: SimpleChanges): void {
  //   if (changes['sampleId']) {
  //     if (typeof this.sampleId !== 'undefined' && this.sampleId > 0) {
  //       this.getSampleId(this.sampleId);
  //     }
  //   }
  // }

  // getSampleId(sampleId: any) {
  //   // console.log(sampleId)
  //   this.productSampleService.getById(sampleId, true).subscribe({
  //     next: (res) => {
  //       this.productSample = res.data
  //     }
  //   })
  // }


  // update() {
  //   const updateDetails = {
  //     productSampleName: this.productSample.productSampleName,
  //     customerName: 'test',
  //     customerId: -1,
  //     productSampleQuantity: parseInt(this.productSample.productSampleQuantity.toString()),
  //     contactPerson: this.productSample.sampleOwner.employeeName,
  //     reference: this.productSample.reference,
  //     buyerId: this.productSample.buyer.buyerId,
  //     sampleOwnerId: this.productSample.sampleOwner.employeeId,
  //     productTypeId: this.productSample.productType.productTypeId,
  //     departmentId: this.productSample.productType.departmentId,
  //     updatedBy: 'Sadeee'
  //   }
  //   this.productSampleService.update(this.sampleId, updateDetails).subscribe({
  //     next: (res) => {
  //     this.router.navigate(['/product-development'])
  //     console.log(res)
  //     }
  //   })
  // }
}

