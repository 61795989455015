import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {DropDown} from 'src/app/core/types/drop-down.type';
import {ProductTypeService} from '../../../services';
import {DOCUMENT} from '@angular/common';
import {Subscription} from 'rxjs';
import {FormsModule} from '@angular/forms';

@Component({
  selector: 'app-search-product-type',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './search-product-type.component.html',
  styleUrl: './search-product-type.component.scss'
})
export class SearchProductTypeComponent implements OnDestroy, OnChanges, OnInit {
  isListVisible: boolean = false
  searchTerm: string | undefined
  options: DropDown[] = []
  productTypeId: number | undefined
  @Output() selectType = new EventEmitter<number>()
  @Input() inputTypeId: number | undefined

  constructor(private productType: ProductTypeService, @Inject(DOCUMENT) private document: Document, private elementRef: ElementRef) { }
  private subscription: Subscription = new Subscription()
  ngOnInit(): void {
    this.get()
  }
  get() {
    this.productType.getAll(true).subscribe({
      next: (res: any) => {

        this.options = res.data.map((product: any) => ({
          id: product.productTypeId,
          text: product.productTypeName,
          status: product.STATUS
        }));
      },
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['inputTypeId']) {
      if (typeof this.inputTypeId != 'undefined' && this.inputTypeId > 0) {
        this.onOptionSelected(this.inputTypeId)
      }
    }
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe()
    // this.document.removeEventListener(false)
  }

  onOptionSelected(id: number) {
    const type = this.options.filter(option => option.id == id)
    if (type && type.length > 0) {
      this.searchTerm = type[0].text
      this.productTypeId = type[0].id
    }
    this.isListVisible = false
    this.selectType.emit(id)
  }

  selectAll(event: MouseEvent): void {
    (event.target as HTMLInputElement).select()
  }

  @HostListener('document:click', ['$event'])
  clickOutsideDropdown(event: any) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isListVisible = false
    }
  }

  handleEscape = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      this.isListVisible = false
    }
  }

}
