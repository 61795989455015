import {Component, effect, inject, signal,} from '@angular/core';
import {
  SearchAddressComponent,
  SearchCurrencyComponent,
  SearchCustomerComponent,
  SearchEmployeeComponent
} from "../../../../../shared";
import {FormsModule} from "@angular/forms";
import {SweetAlert2Module} from "@sweetalert2/ngx-sweetalert2";
import {AsyncPipe} from "@angular/common";
import {SalesOrderService} from "../../../services";
import {CustomerService} from "../../../../../services";
import {SalesOrder} from "../../../interfaces/sales-order.entity";
import {AddressDTO} from "../../../../../interfaces";
import {ToastrService} from "ngx-toastr";
import {NotificationService} from "../../../../../services/notification.service";
import Swal from "sweetalert2";
import {Router, RouterLink} from "@angular/router";
import {APIResponse} from "../../../../../../core";


@Component({
  selector: 'app-sales-order-form',
  standalone: true,
  imports: [
    FormsModule,
    SearchAddressComponent,
    SearchCurrencyComponent,
    SearchCustomerComponent,
    SearchEmployeeComponent,
    SweetAlert2Module,
    AsyncPipe,
    RouterLink,
  ],
  templateUrl: './sales-order-form.component.html',
  styleUrl: './sales-order-form.component.scss'
})
export class SalesOrderFormComponent {

  salesOrderService = inject(SalesOrderService)
  customerService = inject(CustomerService)

  buttonName = 'Create';

  salesOrderId = signal<number>(-1)
  salesOrderReference = signal<string | undefined>(undefined)
  salesOrderStatus = signal<string | undefined>(undefined)
  updateService = inject(SalesOrderService);

  salesOrder: SalesOrder = {
    salesOrderType: 'bulk',
    poDate: '',
    poNumber: '',
    poValue: 0,
    merchandiser: '',
    customerId: -1,
    currencyId: -1,
    salesPersonId: -1,
    customerServiceId: -1,
    invoiceAddressId: 1,
    deliveryAddressId: 2,
  }

  deliveryAddresses: AddressDTO[] = [];
  invoiceAddresses: AddressDTO[] = [];

  constructor(private router: Router,
              private toasterService: ToastrService,
              private notificationService: NotificationService) {
    effect(() => {
      const salesOrder = this.salesOrderService.active();
      if (salesOrder) {
        this.salesOrderId.set(salesOrder.salesOrderId)
        this.buttonName = 'Update'
        this.getCustomer(salesOrder.customer.customerId)
        this.salesOrderReference.set(salesOrder.salesOrderReference)
        this.salesOrderStatus.set(salesOrder.salesOrderStatus)
        this.salesOrder.salesOrderType = salesOrder.salesOrderType
        this.salesOrder.poNumber = salesOrder.poNumber
        this.salesOrder.poDate = salesOrder.poDate
        this.salesOrder.poValue = salesOrder.poValue
        this.salesOrder.salesPersonId = salesOrder.salesPerson.employeeId
        this.salesOrder.merchandiser = salesOrder.merchandiser
        this.salesOrder.customerServiceId = salesOrder.customerService.employeeId
        this.salesOrder.invoiceAddressId = <number>salesOrder.invoiceAddress?.addressId
        this.salesOrder.deliveryAddressId = <number>salesOrder.deliveryAddress?.addressId
      }
    }, {allowSignalWrites: true})
    effect(() => {
      const customer = this.customerService.active();
      if (customer) {
        this.salesOrder.customerId = customer.customerId
        this.invoiceAddresses = customer.addresses.filter((item: AddressDTO) => {
          if (item.isDefaultInvoice) {
            this.salesOrder.invoiceAddressId = item.addressId
          }
          return item.useForInvoice;
        })
        this.deliveryAddresses = customer.addresses.filter((item: AddressDTO) => {
          if (item.isDefaultDelivery) {
            this.salesOrder.deliveryAddressId = item.addressId
          }
          return item.useForDelivery;
        })
        this.salesOrder.currencyId = customer.currency.currencyId
      }
    }, {allowSignalWrites: true});

    effect(() => {
      if (this.updateService.getUpdateTrigger()()) {
        this.onSubmit();
        this.updateService.getUpdateTrigger().set(true);
      }
    }, {allowSignalWrites: true})
  }
  ensurePositiveValue() {
    if (this.salesOrder.poValue < 0) {
      this.salesOrder.poValue = 0;
    }
  }

  getCustomer = (customerId: any) => {
    this.customerService.getById(customerId, false)
      .subscribe({
        error: (error) => {
          console.error('Error:', error)
        },
      })
  }

  selectSalesPerson = (id: number) => {
    this.salesOrder.salesPersonId = id
  }
  selectCustomerService = (id: number) => {
    this.salesOrder.customerServiceId = id
  }

  validate = () => {
    this.salesOrderService.checkForExist(this.salesOrder.poNumber, this.salesOrder.customerId, true).subscribe({
      next: (res: any) => {
        if (res.data.record_count > 0) {
          this.notificationService.showNotification({
            type: 'error',
            message: 'This purchase order is already available in the system.',
            timeout: 7000
          });
        }
      }
    });
  }

  onSubmit = () => {
    if (this.validateForm()) {
      if (this.salesOrderId() > 0) {
        this.updateSalesOrder();
      } else {
        this.createSalesOrder();
      }
    } else {
      this.showValidationErrors();
    }
  }

  validateForm(): boolean {
    const requiredFields = [
      { field: this.salesOrder.poNumber, name: 'Purchase Order Number' },
      { field: this.salesOrder.poDate, name: 'Purchase Order Date' },
      { field: this.salesOrder.poValue, name: 'Order Value' },
      { field: this.salesOrder.currencyId, name: 'Currency' },
      { field: this.salesOrder.merchandiser, name: 'Merchandiser' },
      { field: this.salesOrder.customerServiceId, name: 'Sales Person' },
      { field: this.salesOrder.customerId, name: 'Customer Service' },
      { field: this.salesOrder.invoiceAddressId, name: 'Invoice Address' },
      { field: this.salesOrder.deliveryAddressId, name: 'Delivery Address' }
    ];

    this.emptyFields = requiredFields.filter(item => !item.field);
    return this.emptyFields.length === 0;
  }
  emptyFields: { field: any, name: string }[] = [];

  showValidationErrors() {
    if (this.emptyFields.length > 0) {
      const fieldNames = this.emptyFields.map(item => item.name).join(', ');
      this.notificationService.showNotification({
        type: 'error',
        message: `Please fill all the fields: ${fieldNames}`
      });
    }
  }

  createSalesOrder() {
    this.salesOrderService.create(this.salesOrder).subscribe({
      next: (res) => {
        this.notificationService.showNotification({
          type: 'success',
          message: `Sales Order for PO Number ${this.salesOrder.poNumber} has been successfully created.`,
        });
        setTimeout(() => {
          window.location.reload()
        }, 3000);
      },
      error: (err) => {
        this.notificationService.showNotification({
          type: 'error',
          message: 'Failed to create this sales-order'
        });
        console.error('Error:',err)
      }
    })
  }

  updateSalesOrder() {
    this.salesOrderService.update(this.salesOrderId(), this.salesOrder).subscribe({
      next: (response: APIResponse<any>) => {
        this.notificationService.showNotification({
          type: 'success',
          message: `Sales Order for PO Number ${this.salesOrder.poNumber} has been updated successfully.`,
        });
        this.salesOrderService.all();
        setTimeout(() => {
          window.location.reload()
        }, 3000);
      },
      error: (error: any) => {
        this.notificationService.showNotification({
          type: 'error',
          message: 'Failed to create this sales order. Please try again.'
        });
        console.log(error)
      }
    });
  }

  selectAll(event: MouseEvent): void {
    (event.target as HTMLInputElement).select()
  }
  navigateToSalesOrderList = () => {
    setTimeout(() => {
      window.location.reload()
    }, 0);
  }
}
