import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core'
import {FormsModule} from '@angular/forms'
import {HsCodeService} from '../../../services/hs-code.service'
import {DOCUMENT} from '@angular/common'
import {DropDown} from 'src/app/core/types/drop-down.type'

@Component({
  selector: 'app-search-hs-code',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './search-hs-code.component.html',
  styleUrl: './search-hs-code.component.scss'
})
export class SearchHsCodeComponent implements OnDestroy,OnChanges,OnInit {
  isListVisible: boolean = false
  options: DropDown[] = []
  hsCode: DropDown[] = []
  searchTerm: string | undefined
  hscodeId: number | undefined

  @Input() hsCodeInput: number | undefined
  @Output() selectBuyer = new EventEmitter<number>()

  constructor(private hdCodeService: HsCodeService, @Inject(DOCUMENT) private document: Document, private elementRef: ElementRef) {}
  ngOnDestroy(): void {
    throw new Error('Method not implemented.')
  }

  ngOnInit(): void {
    this.hdCodeService.getAll(true)
    .subscribe({
      next: (res) => {
        this.hsCode = res.data.map(hsCode => ({
          id: hsCode.hsCodeId,
          text: hsCode.hsCode,
          status: hsCode.hsCodeStatus
        }))
        this.options = [...this.hsCode]
      },
      error: (error) => console.error(error)
    })
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes['hsCodeInput']) {
      if (typeof this.hsCodeInput != 'undefined' && this.hsCodeInput > 0) {
        this.onOptionSelected(this.hsCodeInput)
      }
    }
  }

  onOptionSelected(id: number) {
    const hscode = this.options.filter(option => option.id == id)
    if (hscode && hscode.length > 0) {
      this.searchTerm = hscode[0].text
      this.hscodeId = hscode[0].id
    }
    this.isListVisible = false
    this.selectBuyer.emit(id)
  }

  selectAll(event: MouseEvent): void {
    (event.target as HTMLInputElement).select()
  }

}
