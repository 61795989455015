import {Injectable} from '@angular/core';
import {SignInSession, UserCredential} from '../types/userAuth.types';
import {Router} from '@angular/router';
import { HttpClient } from '@angular/common/http';
import {catchError, tap} from 'rxjs/operators';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {environment} from '../../../environments/environment';
import {APIResponse} from "../interfaces";
import {APIRequest} from "../classes";
import {APIRequestResources} from "../enums";

@Injectable({
  providedIn: 'root',
})
export class AuthService extends APIRequest{

  private apiUrl = `${environment.API_BASE}/auth/login`;
  private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);

  constructor(protected override http: HttpClient, private router: Router) {
    super(http, APIRequestResources.AuthService)
    const token = localStorage.getItem('authToken');
    this.isAuthenticatedSubject.next(!!token);
  }

  isAuthenticated(): Observable<boolean> {
    return this.isAuthenticatedSubject.asObservable();
  }

  signIn(userCredentials: UserCredential) {
    return this.http.post<APIResponse<SignInSession>>(this.apiUrl, userCredentials).pipe(
      tap((res) => {
        // console.log(res)
        if (res && res.data) {
          this.userSignIn(res.data);
        } else {
          this.navigateToErrorRoute();
          this.isAuthenticatedSubject.next(false); // Ensure authentication state is set correctly
        }
      }),
      catchError((error) => {
        this.navigateToErrorRoute();
        this.isAuthenticatedSubject.next(false); // Ensure authentication state is set correctly
        return of({} as SignInSession);
      })
    );
  }

  private navigateToErrorRoute() {
    this.router.navigate(['/error']).then(success => {
      if (!success) {
        console.error('Navigation to /error failed');
      }
    }).catch(err => {
      console.error('Navigation error:', err);
    });
  }

  private navigateAfterSignin(accessToken: string) {
    if (accessToken) {
      this.router.navigate(['/home']).then(success => {
        if (!success) {
          this.navigateToErrorRoute();
        }
      }).catch(err => {
        this.navigateToErrorRoute();
      });
    } else {
      this.navigateToErrorRoute();
    }
  }

  isSessionCreated(): boolean {
    const userSessionData = sessionStorage.getItem('user-auth');
    return !!userSessionData;
  }

  userSignIn(userData: SignInSession) {
    const { accessToken, refreshToken, name, userPermissions } = userData || {};

    if (!accessToken || !refreshToken || !name) {
      console.error('Invalid sign-in response:', userData);
      this.navigateToErrorRoute();
      return;
    }

    let userAuth: SignInSession = {
      name,
      accessToken,
      refreshToken,
      userPermissions: JSON.stringify(userPermissions),
    };
    sessionStorage.setItem('user-auth', JSON.stringify(userAuth));
    this.isAuthenticatedSubject.next(true);
    this.navigateAfterSignin(accessToken);
  }

  getAccessToken(): string | null {
    return sessionStorage.getItem('access-token');
  }

  isUserExist(): boolean {
    const userSessionData = sessionStorage.getItem('user-auth');
    return !!userSessionData;
  }

  getAuthToken(): string {
    const userSessionData = sessionStorage.getItem('user-auth') ?? 'NAU';
    if (userSessionData === 'NAU') {
      return '';
    }
    const userData: { accessToken: string } = JSON.parse(userSessionData);
    return userData.accessToken;
  }

  userSignOut(): void {
    sessionStorage.removeItem('access-token');
    sessionStorage.removeItem('user-auth');
    sessionStorage.removeItem('user-permissions');
    this.router.navigate(['/login']);
    this.isAuthenticatedSubject.next(false);
  }
}
