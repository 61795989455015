import {Component, effect, inject, input, OnInit, signal} from '@angular/core';
import {SlicePipe} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {CustomerService, SalesOrderService} from "../../../../../services";
import {TablePaginationComponent} from "../../../../../shared";
import {SearchSalesOrder} from "../../../interfaces/sales-order.entity";
import {RouterLink} from "@angular/router";
import {finalize} from 'rxjs/operators';
import {DotLoadingServices} from "../../../../../../core/services/dot-loading.Services";

@Component({
  selector: 'app-sales-order-grid',
  standalone: true,
  imports: [
    SlicePipe,
    TablePaginationComponent,
    FormsModule,
    RouterLink
  ],
  templateUrl: './sales-order-grid.component.html',
  styleUrl: './sales-order-grid.component.scss'
})
export class SalesOrderGridComponent implements OnInit{
  customerService = inject(CustomerService);
  salesOrderService = inject(SalesOrderService);
  dotLoadingServices = inject(DotLoadingServices)


  selectedSalesOrderId = signal<number>(-1);
  totalItems: number = 0;
  itemsPerPage: number = 10;
  searchParams: SearchSalesOrder = {
    sales_order_date: '',
    sales_order_reference: '',
    po_number: '',
    customer_id: '-1',
    items_per_page: '10',
    page_number: '1'
  };
  defaultSearchParams = input.required<SearchSalesOrder>();

  constructor() {

    effect(() => {
      this.searchParams = this.defaultSearchParams();
      this.searchParams.customer_id = this.customerService.active() ? String(this.customerService.active()?.customerId) : '-1';
      this.fetchSalesOrders(this.searchParams);
    }, {allowSignalWrites: true});

    effect(() => {
      this.totalItems = this.salesOrderService.stat().totalItems;
      this.itemsPerPage = this.salesOrderService.stat().itemsPerPage;
    });

    effect(() => {
      this.salesOrderService.all();
    });
  }

  handleSalesOrderClick(salesOrderId: number) {
    this.selectedSalesOrderId.set(salesOrderId);
    this.dotLoadingServices.setLoading(true);
    this.salesOrderService.getById(String(this.selectedSalesOrderId()), true)
        .pipe(finalize(() => this.dotLoadingServices.setLoading(false)))
        .subscribe({
          error: (err) => {
            console.error('Error while fetching sales order details, ', err);
          }
        });
  }

  fetchSalesOrders = (searchParams: SearchSalesOrder) => {
    this.salesOrderService.find(searchParams,true)
        .pipe(finalize(() => this.dotLoadingServices.setLoading(false)))
        .subscribe({
          next: (res) => {
          },
          error: (err) => {
            console.error('Error while fetching sales orders, ', err);
          }
        });
  }

  onPageChange(pageNumber: number) {
    this.fetchSalesOrders({
      ...this.searchParams,
      page_number: String(pageNumber)
    });
  }

  ngOnInit(): void {
    this.salesOrderService.all();
  }
}