import {Component} from '@angular/core'
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome'
import {faBell, faComment, faListSquares, faMagnifyingGlass, faUser} from '@fortawesome/free-solid-svg-icons'

@Component({
  selector: 'app-app-top-nav',
  standalone: true,
  imports: [FontAwesomeModule],
  templateUrl: './app-top-nav.component.html',
  styleUrl: './app-top-nav.component.scss'
})
export class AppTopNavComponent {
  get faMagnifyingGlass() {
    return faMagnifyingGlass
  }
  get faListSquares() {
    return faListSquares
  }
  get faBell() {
    return faBell
  }
  get faUser() {
    return faUser
  }
  get faComment() {
    return faComment
  }
}
