import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router, RouterModule} from '@angular/router';
import {Subject, takeUntil} from 'rxjs';
import {navbarData} from './nav-data';
import {AuthService} from "../../../../core";
import {AppTopNavComponent} from "../app-top-nav/app-top-nav.component";
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {filter} from "rxjs/operators";

@Component({
  selector: 'app-app-side-nav',
  standalone: true,
  templateUrl: './app-side-nav.component.html',
  styleUrl: './app-side-nav.component.scss',
  imports: [AppTopNavComponent, CommonModule, RouterModule, NgOptimizedImage]
})
export class AppSideNavComponent implements OnInit, OnDestroy {
  navData = navbarData;
  selectedNavItem: string | null = null;
  subMenuVisible: boolean = false;
  private destroy$ = new Subject<void>();

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit() {
    this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
    ).subscribe(() => {
      this.updateSelectedNavItem();
    });

    this.updateSelectedNavItem();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  updateSelectedNavItem() {
    const currentUrl = this.router.url;
    const activeNavItem = this.navData.find(item => currentUrl.includes(item.routeLink));
    if (activeNavItem) {
      this.selectedNavItem = activeNavItem.label;
    } else {
      // this.selectedNavItem = null;
    }
  }

  toggleSubMenu(label: string) {
    if (this.selectedNavItem === label) {
      this.subMenuVisible = !this.subMenuVisible;
    } else {
      this.selectedNavItem = label;
      this.subMenuVisible = true;
    }
  }
}