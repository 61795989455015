import {Injectable} from "@angular/core";
import {CachedAPIRequest} from "../../core/classes/cached.api.request.class";
import { HttpClient } from "@angular/common/http";
import {APIRequestResources} from "../../core";
import {take, tap} from "rxjs";
import {BehaviorSubject} from "rxjs/internal/BehaviorSubject";
import {Unit, UnitDTO} from "../interfaces/unit.entity";
import {toSignal} from "@angular/core/rxjs-interop";


@Injectable({
  providedIn: 'root'
})
export class UnitService extends CachedAPIRequest {

  private readonly $all = new BehaviorSubject<Unit []>([]);
  all = toSignal(this.$all, { initialValue: [] });

  private readonly $active = new BehaviorSubject<UnitDTO  | undefined>(undefined)
  active = toSignal(this.$active, {initialValue: undefined})

  constructor(protected override http: HttpClient) {
    super(http, APIRequestResources.UnitService);
    this.getAll().pipe(take(1)).subscribe()

  }

  getAll(refresh = false) {
    return this.get<Unit []>({ endpoint: `all` }, refresh ? 'freshness' : 'performance').
    pipe(tap(res => this.$all.next(res.data ?? [])))
  }

  getById = (id: string, refresh = false) => {
    return this.get<UnitDTO >({id}, refresh ? 'freshness' : 'performance')
      .pipe(
        tap((res) => this.$active.next(res.data)),
      )
  }


}
