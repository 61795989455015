import {Injectable} from '@angular/core'
import {BehaviorSubject, take, tap} from "rxjs"
import {BuyerDTO, CreateCurrency, CreateCurrencyHistory, CurrencyDTO, UpdateBuyer} from "../interfaces"
import { HttpClient } from "@angular/common/http"
import {Router} from "@angular/router"
import {APIRequestResources, APIResponse, CachedAPIRequest} from "../../core"
import {toSignal} from "@angular/core/rxjs-interop";

@Injectable({
  providedIn: 'root'
})
export class CurrencyService extends CachedAPIRequest {

  private readonly $all = new BehaviorSubject<CurrencyDTO[]>([]);
  all = toSignal(this.$all, {initialValue: []})

  private readonly $active = new BehaviorSubject<CurrencyDTO | undefined>(undefined);
  active = toSignal(this.$active, {initialValue: undefined})

  constructor(protected override http: HttpClient, private router: Router) {
    super(http, APIRequestResources.CurrencyService)
  }

  getById = (id: any, refresh = false) => {
    return this.get<CurrencyDTO>({id}, refresh ? 'freshness' : 'performance')
      .pipe(
        tap((res) => this.$active.next(res.data)),
      )
  }

  getAll = (refresh = true) => {
    return this.get<CurrencyDTO[]>({ endpoint: `all` }, refresh ? 'freshness' : 'performance')
      .pipe(
        tap((res) => this.$all.next(res.data)),
      )
  }

  getExchangeRates(year: string, month: string, refresh = false) {
    return this.get<CurrencyDTO>({ endpoint: `find`, suffix: `${ year }/${ month }`}, refresh ? 'freshness' : 'performance').pipe(take(1))
  }

  getExchangeRate(year: string, month: string, id: number, refresh = false) {
    return this.get<CurrencyDTO>({ endpoint: `find`, suffix: `${ year }/${ month }/${id}`}, refresh ? 'freshness' : 'performance').pipe(take(1))
  }

  create = (currency: CreateCurrency) => {
    return this.post<APIResponse<CurrencyDTO>>(currency,{}).pipe(
      tap(() => this.getAll( true))
    )
  }

  update = (id: string, buyer: UpdateBuyer) => {
    return this.patch<APIResponse<BuyerDTO>>(buyer,{ id }).pipe(
      tap(() => this.getById(id))
    )
  }

  updateBaseCurrency = (id: string) => {
    return this.patch<APIResponse<BuyerDTO>>({},{ endpoint: `base-currency`, suffix: id }).pipe(
      tap(() => this.getById(id, true))
    )
  }

  updateExchangeRates = (currencyHistory: CreateCurrencyHistory) => {
    return this.patch<APIResponse<BuyerDTO>>(currencyHistory,{ endpoint: `exchange-rates` }).pipe(
      tap(() => this.getExchangeRates(currencyHistory.currencyYear, currencyHistory.currencyMonth, true))
    )
  }

}
