import {Component, effect, inject, input, signal} from '@angular/core';
import {Router} from '@angular/router';
import {SalesOrderService} from 'src/app/components/feature/sales-order/services/sales-order.service';
import {FormsModule} from "@angular/forms";
import {TablePaginationComponent} from "../../../../../shared";
import {SalesOrderItemService} from "../../../services";
import {SearchSalesOrderItem} from "../../../interfaces/sales-order-item.entity";
import {finalize} from 'rxjs/operators';
import {DotLoadingServices} from "../../../../../../core/services/dot-loading.Services";

@Component({
  selector: 'app-sales-order-item-grid',
  standalone: true,
  imports: [
    TablePaginationComponent,
    FormsModule,
  ],
  templateUrl: './sales-order-item-grid.component.html',
  styleUrl: './sales-order-item-grid.component.scss'
})
export class SalesOrderItemGridComponent {
  salesOrderService = inject(SalesOrderService);
  salesOrderItemService = inject(SalesOrderItemService);
  dotLoadingServices = inject(DotLoadingServices);

  selectedSalesOrderItemId = signal<number>(-1);
  totalItems: number = 0;
  itemsPerPage: number = 10;
  searchParams: SearchSalesOrderItem = {
    sales_order_id: '-1',
    plan_card_number: '',
    customer_reference: '',
    artwork_reference: '',
    description: '',
    customer_id: '-1',
    sample_id: '-1',
    items_per_page: '10',
    page_number: '1'
  };
  defaultSearchParams = input.required<SearchSalesOrderItem>();

  get salesOrderItems() {
    return this.salesOrderItemService.all();
  }

  constructor(private router: Router) {
    effect(() => {
      const salesOrder = this.salesOrderService.active();
      this.searchParams = this.defaultSearchParams();
      this.searchParams.sales_order_id = salesOrder ? String(salesOrder.salesOrderId) : '-1';
      this.fetchSalesOrderItems(this.searchParams);
    }, {allowSignalWrites: true});

    effect(() => {
      const stat = this.salesOrderItemService.stat();
      if (stat) {
        this.totalItems = stat.totalItems;
        this.itemsPerPage = stat.itemsPerPage;
      }
    });
  }

  handleSalesOrderItemClick(salesOrderItemId: number) {
    this.selectedSalesOrderItemId.set(salesOrderItemId);
    this.dotLoadingServices.setLoading(true);
    this.salesOrderItemService.getById(String(this.selectedSalesOrderItemId()), false)
        .pipe(finalize(() => this.dotLoadingServices.setLoading(false)))
        .subscribe({
          error: (err) => {
            console.error('Error while fetching sales order item details, ', err);
          }
        });
  }

  fetchSalesOrderItems = (searchParams: SearchSalesOrderItem) => {
    this.dotLoadingServices.setLoading(true);
    this.salesOrderItemService.find(searchParams, true)
        .pipe(finalize(() => this.dotLoadingServices.setLoading(false)))
        .subscribe({
          error: (err) => {
            console.error('Error while fetching sales order items, ', err);
          }
        });
  }

  onPageChange(pageNumber: number) {
    this.fetchSalesOrderItems({
      ...this.searchParams,
      page_number: String(pageNumber)
    });
  }
}