import {Injectable} from '@angular/core'
import { HttpClient } from "@angular/common/http"
import {Router} from "@angular/router"
import {APIRequestResources, APIResponse, PaginationResponse} from "../../core"
import {BuyerDTO, CreateBuyer, UpdateBuyer} from "../interfaces"
import {BehaviorSubject, take, tap} from "rxjs"
import {CachedAPIRequest} from "../../core/classes/cached.api.request.class"

@Injectable({
  providedIn: 'root'
})
export class BuyerService extends CachedAPIRequest {

  private readonly $all = new BehaviorSubject<BuyerDTO[]>([])
  all$ = this.$all.asObservable()

  constructor(protected override http: HttpClient, private router: Router) {
    super(http, APIRequestResources.BuyerService)
  }

  getAll(refresh = false) {
    return this.get<PaginationResponse<BuyerDTO[]>>({
      endpoint: `find`,
      params: {status: ``, page_number: 1, items_per_page: 10}
    }, refresh ? 'freshness' : 'performance').pipe(tap(res => this.$all.next(res.data.data ?? [])))
  }


  find(params: any, refresh = false) {
    return this.get<PaginationResponse<BuyerDTO[]>>({
      endpoint: `find`,
      params: params
    }, refresh ? 'freshness' : 'performance').pipe(take(1))
  }

  getById(id: string, refresh = false) {
    return this.get<BuyerDTO>({id}, refresh ? 'freshness' : 'performance').pipe(take(1))
  }

  create = (buyer: CreateBuyer) => {
    return this.post<BuyerDTO>(buyer, {}).pipe(
      tap(() => this.getAll(true))
    )
  }

  update = (id: string, buyer: UpdateBuyer) => {
    return this.patch<APIResponse<BuyerDTO>>(buyer, {id}).pipe(
      tap(() => this.getById(id))
    )
  }

}
