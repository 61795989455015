import { HttpClient } from "@angular/common/http";
import {APIRequestOptions, APIResponse} from "../interfaces";
import {ApiConfig} from "../config";
import {MASPortalResources} from "../enums";

export abstract class MASPortalApiRequest {

  protected constructor(protected http: HttpClient, protected resource: MASPortalResources) {
  }

  public get<T>(options: APIRequestOptions) {
    return this.http.get<APIResponse<T>>(this.generateUrl(options), {
      params: options.params,
      headers: options.headers || {}
    })
  }

  public post<T>(data: unknown, options: APIRequestOptions) {
    return this.http.post<APIResponse<T>>(this.generateUrl(options), data, {
      params: options.params,
      headers: options.headers
    })
  }

  public async upload(file: File, options: APIRequestOptions) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.post(formData, options)
  }

  private generateUrl({id, resource, endpoint, suffix}: APIRequestOptions) {
    return [ApiConfig.portalURL, resource ?? this.resource, id, endpoint, suffix].filter(x => !!x).join('/').split('://').map(p => p.replace(/\/\//, '/')).join('://')
  }

}
