export enum APIRequestResources {
  ApplicationService = 'application',
  AuthService = 'auth',
  BuyerService = 'buyer',
  CustomerCategoryService = 'customer-category',
  CustomerReferenceService = 'customer-reference',
  CustomerService = 'customer',
  CurrencyService = 'currency',
  DepartmentService = 'department',
  EmployeeService ='employee',
  HsCodeService = 'hs-code',
  IdentityService = 'identity',
  InvoiceService = 'invoice',
  PackingListService='packing-list',
  ProductDesignService='product-design',
  ProductSampleService='product-sample',
  ProductTypeService='product-type',
  ResourcesService = 'identity/resources',
  SalesOrderItemService='sales-order-item',
  SalesOrderService ='sales-order',
  UsersService = 'identity/users',
  UnitService = 'unit',
  MaximAPIService = '',
  CustomerPriceService = 'customer-price',
  ReportService = 'report',
  DeliveryService = 'mas-portal'
}

export enum MASPortalResources {
  PurchaseOrderDownloadService = 'purchase-order',
  DeliveryConfirmationService = 'delivery-confirmation',
}
