import { HttpClient } from '@angular/common/http'
import {Injectable} from '@angular/core'
import {Router} from '@angular/router'
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject'
import {CachedAPIRequest} from 'src/app/core/classes/cached.api.request.class'
import {APIRequestResources} from 'src/app/core/enums'
import {DepartmentDTO} from '../interfaces'
import {take, tap} from 'rxjs'
import {PaginationResponse} from "../../core";


@Injectable({
  providedIn: 'root'
})
export class DepartmentService extends CachedAPIRequest {

  private readonly $all = new BehaviorSubject<DepartmentDTO[]>([])
  all$ = this.$all.asObservable()

  constructor(protected override http: HttpClient, private router: Router) {
    super(http, APIRequestResources.DepartmentService)
  }

  getAll = (refresh = false) => {
    return this.get<DepartmentDTO[]>({
      endpoint: `find`,
      params: {use_for_gpd: '', page_number: 0, items_per_page: 10}
    }, refresh ? 'freshness' : 'performance')
      .pipe(tap(res => this.$all.next(res.data ?? [])))
  }

  find = (params: any, refresh = false) => {
    return this.get<PaginationResponse<DepartmentDTO[]>>({
        endpoint: `find`,
        params: params
      },
      refresh ? 'freshness' : 'performance').pipe(take(1))
  }

}
