import {Component, inject, OnInit, signal,} from '@angular/core';
import {CustomerService, SalesOrderService} from '../../../../services';
import {SearchSalesOrder} from '../../interfaces/sales-order.entity';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SearchCustomerComponent, UserProfileComponent} from '../../../../shared';
import {Router} from '@angular/router';
import {SalesOrderFormComponent, SalesOrderGridComponent} from "../../shared";
import {NgOptimizedImage} from "@angular/common";
import {NotificationService} from "../../../../services/notification.service";
import {DotLoadingServices} from "../../../../../core/services/dot-loading.Services";


@Component({
  selector: 'app-sales-order',
  standalone: true,
  templateUrl: './sales-order.component.html',
  styleUrls: ['./sales-order.component.scss'],
  imports: [
    SalesOrderFormComponent,
    SalesOrderGridComponent,
    SalesOrderGridComponent,
    FormsModule,
    ReactiveFormsModule,
    SearchCustomerComponent,
    UserProfileComponent,
    NgOptimizedImage
  ]
})
export class SalesOrderComponent implements OnInit{

  updateService = inject(SalesOrderService);
  customerService = inject(CustomerService)
  salesOrderService = inject(SalesOrderService)
  salesOrderId = signal(0);
  searchOption = signal<string>('sales_order_reference')
  searchValue = signal<string>('')

  currentView: 'search' | 'breakdown' | 'create' | 'item' | 'update' = 'search';

  searchSalesOrderParams: SearchSalesOrder = {
    sales_order_date: '',
    sales_order_reference: '',
    po_number: '',
    customer_id: '',
    items_per_page: '10',
    page_number: '1'
  };

  constructor(
    private router: Router,
    private notificationService: NotificationService,
    private dotLoadingServices: DotLoadingServices) {}

  get customer() {
    return this.customerService.active()
  }
  get salesOrder() {
    return this.salesOrderService.active()
  }

  setSearchOption(value: string): void {
    this.searchOption.set(value);
    this.searchValue.set('');
  }
  setSearchValue(value: string): void {
    const trimmedValue = value.replace(/\s/g, '');
    this.searchValue.set(trimmedValue);
  }
  onSearchInput(event: any): void {
    const inputValue = event.target.value;
    const trimmedValue = inputValue.replace(/\s/g, '');

    if (inputValue !== trimmedValue) {
      event.target.value = trimmedValue;
      this.setSearchValue(trimmedValue);
    }
  }
  searchSalesOrder = () => {
    this.currentView='search'
    let searchParams = {...this.searchSalesOrderParams}
    if (this.searchOption() != '') {
      const trimmedSearchValue = this.searchValue().trim(); // Ensure no leading/trailing spaces
      switch (this.searchOption()) {
        case 'sales_order_reference':
          searchParams.sales_order_reference = trimmedSearchValue != '' ? trimmedSearchValue : ''
          break
        case 'sales_order_date':
          searchParams.sales_order_date = trimmedSearchValue != '' ? trimmedSearchValue : ''
          break
        case 'po_number':
          searchParams.po_number = trimmedSearchValue != '' ? trimmedSearchValue : ''
          break
      }
      if (this.customer) {
        searchParams.customer_id = String(this.customer.customerId)
      }
      this.dotLoadingServices.setLoading(true);
      this.salesOrderService.find(searchParams).subscribe({
        next: response => {
          // console.log(response);
          if (response.data.totalItems === 0) {
            let searchCriteria = this.getSearchCriteria();
            this.notificationService.showNotification({
              type: 'error',
              message: `No Results Found for ${searchCriteria}`
            });
            // Reset search params to show all data
            this.resetSearchParams();
            this.searchSalesOrder(); // Call again to fetch all data
          }
          this.dotLoadingServices.setLoading(false);
        },
        error: err => {
          console.error(err);
          this.dotLoadingServices.setLoading(false);
        }
      });
    }
  }
  getSearchCriteria(): string {
    switch (this.searchOption()) {
      case 'sales_order_reference':
        return `Order Reference "${this.searchValue()}"`;
      case 'sales_order_date':
        return `Sales Order Date "${this.searchValue()}"`;
      case 'po_number':
        return `Customer PO "${this.searchValue()}"`;
      default:
        return 'the given criteria';
    }
  }
  resetSearchParams() {
    this.searchSalesOrderParams = {
      sales_order_date: '',
      sales_order_reference: '',
      po_number: '',
      customer_id: '',
      items_per_page: '10',
      page_number: '1'
    };
    this.searchOption.set('sales_order_reference');
    this.searchValue.set('');
  }

  showCreateForm() {
    this.salesOrderService.initial();
    this.currentView = 'create';
  }

  showUpdateForm() {
    this.currentView = 'update';
  }

  getPlaceholder(): string {
    switch (this.searchOption()) {
      case 'po_number':
        return 'Customer PO#';
      case 'sales_order_reference':
        return 'Order Reference';
      case 'sales_order_date':
        return 'Sales Order Date';
      default:
        return 'Search';
    }
  }

  showViewForm() {
    this.router.navigate(['/sales-order-item']);
  }
  orderDownloadView() {
    this.router.navigate(['/mas-order-receive']);
  }
  showSearchForm() {
    this.currentView = 'search';
  }
  ngOnInit(): void {
    this.salesOrderService.initial()
  }
  updateSalesOrder() {
    this.updateService.triggerUpdate();
  }
 deliverConfirmationView() {
    this.router.navigate(['/mas-order-delivery']);
  }
}
