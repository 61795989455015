<div class="searchable-dropdown">
  <input
    (click)="selectAll($event)"
    (focus)="this.isListVisible = true"
    (ngModelChange)="onInputChange($event)"
    [ngModel]="filterValue()"
    class="form-input"
    placeholder="Select Units"
    type="text"
  />
  @if (isListVisible) {
    @if (isListVisible) {
      <ul class="list">
        @for (option of units; track option) {
          <li class="item" (click)="onOptionSelected(option.unitId)">
            {{ option.unitName }}
          </li>
        }
      </ul>
    }
  }
</div>
