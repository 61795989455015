import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";
import {AuthService} from "../../services";
import {FormsModule} from '@angular/forms';
import {NgClass, NgIf} from "@angular/common";

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [FormsModule, NgClass, NgIf],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  username: string = '';
  password: string = '';
  showError: boolean = false;
  private querySub: Subscription | undefined;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.querySub = this.activatedRoute.queryParams.subscribe(params => {
      const keyValue = params['key'];
      if (keyValue) {
        this.authService.signIn({ username: keyValue, password: '' }).subscribe(
          response => {
            console.log('Login successful');
          },
          error => {
            console.error('Login failed', error);
          }
        );
      }
    });
  }

  onSubmit() {
    const userCredentials = {
      username: this.username,
      password: this.password,
    };
    this.authService.signIn(userCredentials).subscribe(
      response => {
        if (this.authService.isSessionCreated()) {
          // console.log('Session created successfully')
          this.showError = false;
        } else {
          // console.error('Session creation failed');
          this.triggerError();
        }
      },
    );
  }
  triggerError() {
    this.showError = false;
    setTimeout(() => this.showError = true, 0);
  }
  ngOnDestroy() {
    if (this.querySub)
      this.querySub.unsubscribe();
  }
}
