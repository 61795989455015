import { HttpClient } from '@angular/common/http'
import {Injectable} from '@angular/core'
import {Router} from '@angular/router'
import {CachedAPIRequest} from 'src/app/core/classes/cached.api.request.class'
import {APIRequestResources} from 'src/app/core/enums/api.request.enum'
import {HsCodeDTO} from '../interfaces/hs-code.entity'
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject'
import {tap} from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class HsCodeService extends CachedAPIRequest {
  private readonly $all = new BehaviorSubject<HsCodeDTO[]>([])
  all$ = this.$all.asObservable()

  constructor(protected override http: HttpClient, private router: Router) {
    super(http, APIRequestResources.HsCodeService)
  }
  getAll = (refresh = false) => {
    return this.get<HsCodeDTO[]>({endpoint:`all`}, refresh ? 'freshness' : 'performance').
    pipe(tap(res => this.$all.next(res.data ?? [])))
  }
}
