import {
  Component,
  computed,
  effect,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Output,
  signal
} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {UnitService} from '../../../services';
import {Unit} from "../../../interfaces/unit.entity";

@Component({
  selector: 'app-search-unit',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
  ],
  templateUrl: './search-unit.component.html',
  styleUrl: './search-unit.component.scss'
})
export class SearchUnitComponent {
  unitService = inject(UnitService);

  @Output() selectedUnitId = new EventEmitter<number>();

  filterValue = signal('');
  unitId = signal(0);

  get units(): Unit[] {
    return this.unitService.all();
  }

  getSelectedUnit = computed(() => {
    return this.unitService.active();
  });

  isListVisible = false;


  constructor(private elementRef: ElementRef) {
    effect(() => {
      this.unitService.all()
    }, { allowSignalWrites: true });

    effect(() => {
      const selectedUnit = this.getSelectedUnit();
      if (selectedUnit !== undefined) {
        this.filterValue.set(selectedUnit.unitName);
      }
    }, { allowSignalWrites: true });
  }

  onInputChange(value: string) {
    this.filterValue.set(value);
  }

  onOptionSelected(id: number) {
    const unit = this.units.find(option => option.unitId === id);
    if (unit) {
      this.unitId.set(id);
      this.isListVisible = false;
      this.selectedUnitId.emit(this.unitId());
      this.unitService.getById(String(id), true).subscribe({
        error: (error) => console.error(error)
      });
    }
  }

  selectAll(event: MouseEvent): void {
    (event.target as HTMLInputElement).select();
  }

  @HostListener('document:click', ['$event'])
  clickOutsideDropdown(event: any) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isListVisible = false;
    }
  }

}
