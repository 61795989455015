import {
  Component,
  effect,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Inject,
  input,
  OnDestroy,
  OnInit,
  Output,
  signal
} from '@angular/core'
import {FormsModule} from '@angular/forms'
import {EmployeeService} from '../../../services'
import {DropDown} from "../../../../core/types/drop-down.type";
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'app-search-employee',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './search-employee.component.html',
  styleUrl: './search-employee.component.scss'
})
export class SearchEmployeeComponent implements OnInit, OnDestroy {

  employeeService = inject(EmployeeService)

  filterValue = signal('')
  employees: DropDown[] = []
  options = signal<DropDown[]>([])

  employeeId = input(-1)
  categoryId = input(-1)
  @Output() selectedEmployee = new EventEmitter<number>();

  isListVisible: boolean = false

  constructor(@Inject(DOCUMENT) private document: Document, private elementRef: ElementRef) {
    effect(() => {
      if (this.categoryId() > 0) {
        this.employees = this.employeeService.all().filter(employee => employee.employeeCategoryId == this.categoryId()).map((employee) => ({
          id: employee.employeeId,
          text: employee.employeeDisplayName,
          status: employee.employeeStatus
        }))
      } else {
        this.employees = this.employeeService.all().map((employee) => ({
          id: employee.id,
          text: employee.displayName,
          status: employee.status
        }))
      }

      this.options.set([...this.employees])
      if (this.filterValue() == '') {
        this.options.set([...this.employees])
      } else {
        this.options.set(this.employees.filter(employee => employee.text.includes(this.filterValue())))
      }
    }, {allowSignalWrites:true});
    effect(() => {
      if (this.filterValue() == '') {
        this.options.set([...this.employees])
      } else {
        this.employees.filter(employee => employee.text.includes(this.filterValue()))
      }
    }, {allowSignalWrites:true});
    effect(() => {
      if (this.employeeId() > 0) {
        const initial = this.options().filter((employee) => employee.id == this.employeeId())
        if (initial.length > 0) {
          this.filterValue.set(initial[0].text)
        }
      }
    }, {allowSignalWrites: true});
  }


  onInputChange(value: string): void {
    this.filterValue.set(value)
  }

  onOptionSelected(id: number) {
    this.isListVisible = false
    this.selectedEmployee.emit(id)
    const employee = this.options().filter((option) => option.id == this.employeeId())
    this.filterValue.set(employee.length > 0 ? employee[0].text : '')
  }

  selectAll(event: MouseEvent): void {
    (event.target as HTMLInputElement).select()
  }

  ngOnInit(): void {
    this.document.addEventListener('keydown', this.handleEscape, false)
  }

  ngOnDestroy() {
    this.document.removeEventListener('keydown', this.handleEscape, false)
  }

  @HostListener('document:click', ['$event'])
  clickOutsideDropdown(event: any) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isListVisible = false
      if (this.employeeId() < 0) {
        this.filterValue.set('')
      }
    }
  }

  handleEscape = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      this.isListVisible = false
      if (this.employeeId() < 0) {
        this.filterValue.set('')
      }
    }
  }

}
