<div class="searchable-dropdown">
  <input type="text" class="input" placeholder="" [(ngModel)]="searchTerm" (focus)="this.isListVisible = true" readonly="readonly" />
  @if (isListVisible) {
    @if (isListVisible) {
      <ul class="list">
        @for (option of options; track option) {
          <li class="item" (click)="onOptionSelected(option.id)">
            {{ option.text }}
          </li>
        }
      </ul>
    }
  }
</div>
