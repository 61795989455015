<div class="relative overflow-x-auto  rounded-xl m-7 ">
  <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
    <thead class="text-xs text-gray-700 bg-gray-50">
      <tr>
        <th scope="col" class="p-4">
        </th>
        <th scope="col" class="px-6 py-3">
          Buyer Name
        </th>
        <th scope="col" class="px-6 py-3">
          Sample Code
        </th>
        <th scope="col" class="px-6 py-3">
          Sample Name
        </th>
        <th scope="col" class="px-6 py-3">
       Sample Owner
        </th>
        <th scope="col" class="px-6 py-3">
          Status
        </th>
      </tr>
    </thead>
    <tbody>
      @for(items of productSample; track items){
        <tr (click)="handleSampleClick(items.id)"
        class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
        <td class="w-4 p-4">
          <div class="flex items-center">
            <input id="checkbox-table-search-2" type="radio"  [(ngModel)]="sampleId" [value]="items.id"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
            <label for="checkbox-table-search-2" class="sr-only">checkbox</label>
          </div>
        </td>
        <td scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
          {{items.buyerName}}
        </td>
        <td class="px-6 py-4">
          {{items.sampleCode}}
        </td>
        <td class="px-6 py-4">
        {{items.sampleName}}
        </td>
        <td class="px-6 py-4">
          {{items.sampleOwner}}
        </td>
        <td class="w-44 px-6 py-4">
          @if (items.status === 'active') {
            <div
              class="bg-[#F3F3F3] text-[#424735] font-medium px-2 py-1 rounded-lg inline-flex items-center">
              <div class="w-2 h-2 mr-2 bg-[#CDF463] rounded-full"></div>
              Active
            </div>
          }
          @if (items.status === 'inactive') {
            <div
              class="bg-[#F3F3F3] text-[#FF4D4D] font-medium px-2 py-1 rounded-lg inline-flex items-center">
              <div class="w-2 h-2 mr-2 bg-[#FF4D4D] rounded-full"></div>
              Inactive
            </div>
          }
        </td>
      </tr>
      }
    </tbody>
  </table>
  <!-- <app-table-pagination></app-table-pagination> -->
</div>
<div>
  <button (click)="sub()">update test</button>
</div>
