import {
  Component,
  computed,
  effect,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Input,
  Output,
  signal
} from '@angular/core';

import {ProductSampleService} from '../../../services';
import {FormsModule} from '@angular/forms';
import {NgForOf, NgIf} from "@angular/common";

@Component({
  selector: 'app-search-sample-number',
  standalone: true,
  imports: [FormsModule, NgForOf, NgIf],
  templateUrl: './search-sample-number.component.html',
  styleUrls: ['./search-sample-number.component.scss']
})
export class SearchSampleNumberComponent {
  @Input() displayField: 'sampleName' | 'sampleCode' = 'sampleName';
  productSampleService = inject(ProductSampleService);
  @Output() selectedSampleId = new EventEmitter<number>();


  searchTerms = signal({
    sample_code: '',
    sample_name: '',
    customer_id: -1,
    buyer_id: -1,
    department_id: -1,
    items_per_page: 10,
    page_number: 1,
  });

  filterValue = signal('');
  sampleId = signal(0);
  @Input() filterKey!: 'sample_code' | 'sample_name' | 'any';

  get productSamples() {
    return this.productSampleService.all();
  }

  selectedProductSample = computed(() => {
    return this.productSampleService.active();
  });
  searchText = this.filterValue();

  isListVisible = false;

  constructor(private elementRef: ElementRef) {
    effect(() => {
      switch (this.filterKey) {
        case 'sample_name':
          this.searchTerms().sample_name = this.searchText;
          this.searchTerms().sample_code = '';
          break;
        case 'sample_code':
          this.searchTerms().sample_code = this.searchText;
          this.searchTerms().sample_name = '';
          break;
        default:
          this.searchTerms().sample_code = this.searchText;
          this.searchTerms().sample_name = this.searchText;
      }
    }, {allowSignalWrites: true});

    effect(() => {
      if (typeof this.selectedProductSample() !== 'undefined') {
        this.filterValue.set(
          this.filterKey === 'sample_code'
            ? this.selectedProductSample()?.productSampleCode as string
            : this.selectedProductSample()?.productSampleName as string
        );
      }
    }, {allowSignalWrites: true});
  }

  onOptionSelected(id: any) {
    this.isListVisible = false;
    this.sampleId.set(id);
    this.selectedSampleId.emit(this.sampleId());
    this.productSampleService.getById(String(id), true).subscribe({
      error: (error) => console.error(error),
    });
  }

  selectAll(event: MouseEvent): void {
    (event.target as HTMLInputElement).select();
  }

  @HostListener('document:click', ['$event'])
  clickOutsideDropdown(event: any) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isListVisible = false;
    }
  }

  onInputChange($event: any) {
    this.searchTerms.set({
      sample_code: '',
      sample_name: $event,
      customer_id: -1,
      buyer_id: -1,
      department_id: -1,
      items_per_page: 10,
      page_number: 1,
    });
  }
}
