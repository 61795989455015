<div class="grid grid-cols-1 md:grid-cols-12 gap-4 px-8 py-4">
    <!-- First column (Search Customer) -->
    <div class="bg-white rounded-lg md:col-span-3">
        <div class="p-6">
            <form class="form">
                @if (salesOrderId() > 0) {
                    <div class="form-group w-full mb-4">
                        <label class="form-label block text-sm font-medium text-gray-900" for="poNumber">Sales Order
                            Reference</label>
                        <input [value]="salesOrderReference()"
                               class="form-input w-full p-2 border border-gray-300 rounded" id="salesOrderReference"
                               name="salesOrderReference" type="text" readonly="readonly" required>
                    </div>
                }
                <div class="form-group w-full mb-4">
                    <label class="form-label block text-sm font-medium text-gray-900" for="poNumber">Purchase Order
                        NO</label>
                    <input (blur)="validate()" [(ngModel)]="salesOrder.poNumber"
                           class="form-input w-full p-2 border border-gray-300 rounded pr-8" id="poNumber"
                           name="poNumber" type="text" required>
                </div>
                <div class="form-group w-full mb-4">
                    <label class="form-label block text-sm font-medium text-gray-900" for="poNumber">Purchase Order
                        Date</label>
                    <input [(ngModel)]="salesOrder.poDate"
                           class="form-input w-full p-2 border border-gray-300 rounded pr-8" id="poDate"
                           name="poDate" type="date" required>
                </div>
                <div class="form-group w-full flex flex-col md:flex-row mb-4">
                    <div class="form-group w-full md:mr-2">
                        <label class="block text-sm font-medium text-gray-900" for="poValue">Order Value</label>
                        <input [(ngModel)]="salesOrder.poValue"
                               class="form-input w-full p-2 border border-gray-300 rounded text-right mt-2"
                               id="poValue"
                               name="poValue"
                               type="number"
                               min="0"
                               (change)="ensurePositiveValue()"
                               required>
                    </div>
                    <div class="form-group w-full md:ml-2">
                        <label class="form-label block text-sm font-medium text-gray-900">Currency</label>
                        <app-search-currency (selectCurrency)="salesOrder.currencyId"
                                             [currencyInput]="salesOrder.currencyId">
                        </app-search-currency>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <!-- Second column (Filter and Search) -->
    <div class="bg-white rounded-lg md:col-span-6">
        <form class="form">
            <div class="pt-4 pl-6 pr-6 w-full flex flex-col mb-4">
                <div class="form-group w-full mb-4">
                    <label class="block mb-2 text-sm font-medium text-gray-900" for="merchandiser">Merchandiser</label>
                    <input [(ngModel)]="salesOrder.merchandiser"
                           class="form-input w-full p-2 border border-gray-300 rounded" id="merchandiser"
                           name="merchandiser"
                           type="text" required>
                </div>
                <div class="form-group w-full mb-4">
                    <label class="form-label block text-sm font-medium text-gray-900">Sales Person</label>
                    <app-search-employee [categoryId]="1" [employeeId]="salesOrder.salesPersonId"
                                         (selectedEmployee)="selectSalesPerson($event)">
                    </app-search-employee>
                </div>
                <div class="form-group w-full">
                    <label class="form-label block text-sm font-medium text-gray-900">Customer Service</label>
                    <app-search-employee [categoryId]="2" [employeeId]="salesOrder.customerServiceId"
                                         (selectedEmployee)="selectCustomerService($event)">
                    </app-search-employee>
                </div>
            </div>
        </form>
    </div>

    <div class="bg-white rounded-lg md:col-span-3">
        <form class="form">
            <div class="pt-4 pl-6 pr-6 w-full flex flex-col mb-4">
                <div class="form-group w-full mb-4">
                    <label class="block mb-2 text-sm font-medium text-gray-900" for="merchandiser">Invoice
                        Address</label>
                    <app-search-address [addressId]="salesOrder.invoiceAddressId" [addresses]="invoiceAddresses">
                    </app-search-address>
                </div>
                <div class="form-group w-full">
                    <label class="form-label block text-sm font-medium text-gray-900">Delivery Address</label>
                    <app-search-address [addressId]="salesOrder.deliveryAddressId" [addresses]="deliveryAddresses">
                    </app-search-address>
                </div>
            </div>
        </form>
    </div>
</div>

<div class="main-container">
    <div class="col-span-2 sub-container grid grid-cols-2">
        <div class="py-2">
            <button (click)="navigateToSalesOrderList()" class="button-new">Cancel</button>
        </div>
        @if (validateForm()) {
            <div class="py-2">
                <button (click)="onSubmit()" class="button-new">{{buttonName}}</button>
            </div>
        } @else {
            <div class="py-2">
                <button class="disabled">{{buttonName}}</button>
            </div>
        }
    </div>
</div>
