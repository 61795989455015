import {Injectable, signal} from '@angular/core'
import {CachedAPIRequest} from 'src/app/core/classes/cached.api.request.class'
import { HttpClient } from '@angular/common/http'
import {Router} from '@angular/router'
import {APIRequestResources} from 'src/app/core/enums/api.request.enum'
import {BehaviorSubject, take, tap} from 'rxjs'
import {SalesOrderDTO, SearchSalesOrderDTO} from "../interfaces/sales-order.entity"
import {PaginationResponse} from "../../../../core";
import {toSignal} from "@angular/core/rxjs-interop";

@Injectable({
  providedIn: 'root'
})
export class SalesOrderService extends CachedAPIRequest {

  private readonly $all = new BehaviorSubject<SearchSalesOrderDTO[]>([]);
  all = toSignal(this.$all, {initialValue: []})

  private readonly $active = new BehaviorSubject<SalesOrderDTO | undefined>(undefined);
  active = toSignal(this.$active, {initialValue: undefined})

  private readonly $statistics = new BehaviorSubject<any>(undefined)
  stat = toSignal(this.$statistics, {initialValue: undefined})

  private updateTriggerSignal = signal<boolean>(false);


  constructor(protected override http: HttpClient, private router: Router) {
    super(http, APIRequestResources.SalesOrderService)
    this.find({
      sales_order_date: '',
      sales_order_reference: '',
      po_number: '',
      customer_id: '',
      items_per_page: '10',
      page_number: '1'
    }, true).pipe(take(1)).subscribe()
  }

  getById = (id: any, refresh = false) => {
    return this.get<SalesOrderDTO>({id}, refresh ? 'freshness' : 'performance')
      .pipe(
        tap((res) => this.$active.next(res.data)),
      )
  }

  find = (searchParams: any, refresh = false) => {
    return this.get<PaginationResponse<SearchSalesOrderDTO[]>>({
      endpoint: `find`,
      params: searchParams,
    }, refresh ? 'freshness' : 'performance')
      .pipe(
        tap((res) => this.$all.next(res.data.data)),
        tap((res) => {
          const {data, ...obj} = res.data
          this.$statistics.next(obj)
        })
      )
  }

  checkForExist = (po_number: string,
                   customer_id: number,
                   refresh = true
  ) => {
    return this.get<SalesOrderDTO[]>({
      endpoint: `exist`,
      params: {
        po_number: po_number,
        customer_id: customer_id,
      },
    }, refresh ? 'freshness' : 'performance')
  }

  create = (salesOrder:any) => {
    return this.post<any>(salesOrder, {})
  }

  update = (salesOrderId: number, salesOrderDetail: any) => {
    const options = {suffix: salesOrderId.toString()};
    return this.patch<any>(salesOrderDetail, options);
  }

  triggerUpdate() {
    this.updateTriggerSignal.set(true);
  }

  getUpdateTrigger() {
    return this.updateTriggerSignal;
  }

  initial  = () => {
    this.$active.next(undefined)
    // this.$all.next([])
  }
}
