import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {Subscription} from "rxjs";
import {DropDown} from "../../../../core/types/drop-down.type";
import {CurrencyService} from "../../../services";
import {DOCUMENT} from "@angular/common";
import {FormsModule} from "@angular/forms";

@Component({
  selector: 'app-search-currency',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './search-currency.component.html',
  styleUrl: './search-currency.component.scss'
})
export class SearchCurrencyComponent implements OnInit, OnDestroy, OnChanges {

  private subscription: Subscription = new Subscription()

  isListVisible: boolean = false
  options: DropDown[] = []
  searchTerm: string | undefined
  currencyId: number | undefined

  @Input() currencyInput: number | undefined
  @Output() selectCurrency = new EventEmitter<number>()

  constructor(private currencyService: CurrencyService, @Inject(DOCUMENT) private document: Document, private elementRef: ElementRef) {
  }

  ngOnInit(): void {
    this.subscription.add(
        this.currencyService.getAll(false)
            .subscribe({
              next: (res) => {
                this.options = res.data.map(currency => ({
                  id: currency.currencyId,
                  text: currency.currencyCode,
                  status: currency.currencyStatus
                }));

                if (typeof this.currencyInput != 'undefined' && this.currencyInput > 0) {
                  this.onOptionSelected(this.currencyInput);
                }
              },
              error: (error) => console.error(error)
            })
    );
    this.document.addEventListener('keydown', this.handleEscape, false);
  }



  ngOnChanges(changes: SimpleChanges) {
    if (changes['currencyInput']) {
      if (typeof this.currencyInput != 'undefined' && this.currencyInput > 0) {
        this.onOptionSelected(this.currencyInput)
      }
    }
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe()
    this.document.removeEventListener('keydown', this.handleEscape, false)
  }

  onOptionSelected(id: number) {
    const currency = this.options.filter(option => option.id == id)
    if (currency && currency.length > 0) {
      this.searchTerm = currency[0].text
      this.currencyId = currency[0].id
    }
    this.isListVisible = false
    this.selectCurrency.emit(id)
  }

  @HostListener('document:click', ['$event'])
  clickOutsideDropdown(event: any) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isListVisible = false
    }
  }

  handleEscape = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      this.isListVisible = false
    }
  }

}
