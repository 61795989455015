<div class="main-container">
  <div class="sub-container">
 <app-search-product-type (selectType)="handleTypeIdclick($event)"></app-search-product-type>
  </div>
  <div class="sub-container col-span-2 grid grid-cols-3">
    <div>
      <select name="" id="" class="form-input">
        <option value="">Sample Name</option>
        <option value="">Sample Name</option>
        <option value="">Sample Name</option>
      </select>
    </div>
    <div>
      <app-search-department></app-search-department>
    </div>
    <div>
      <input type="text" class="form-input">
    </div>
  </div>
  <div class="sub-container">
    <app-user-profile></app-user-profile>
  </div>
</div>
<!--<app-product-development-search-result (selectedSampleId)="handleSampleId($event)"></app-product-development-search-result>-->
<!--<app-update-product-development-form [sampleId]="sampleId"></app-update-product-development-form>-->
