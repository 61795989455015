import {Component, computed, effect, ElementRef, HostListener, Inject, input, output, signal} from '@angular/core'
import {DOCUMENT} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms"
import {AddressDTO} from "../../../interfaces"

@Component({
  selector: 'app-search-address',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule
  ],
  templateUrl: './search-address.component.html',
  styleUrl: './search-address.component.scss'
})
export class SearchAddressComponent {

  // addressId = model(-1)

  addressId = input(-1)
  addresses = input.required<AddressDTO[]>()
  addressText = signal('')

  selectedAddress = output<number>()

  isListVisible: boolean = false

  constructor(@Inject(DOCUMENT) private document: Document, private elementRef: ElementRef) {
    effect(() => {
      if(this.addressId() > 0) {
        const address = this.options().filter((address) => address.id == this.addressId())
        if (address.length > 0) {
          this.addressText.set(address[0].text)
        }
      }
    }, {allowSignalWrites: true});
  }

  // addressText = computed(() => {
  //   console.log(this.addressId())
  //   const address = this.options().filter((address) => address.id = this.addressId())
  //   console.log(address.length > 0 ? address[0].text : '')
  //   return address.length > 0 ? address[0].text : ''
  // })

  options = computed(() => {
    return this.addresses().map(address => ({
      id: address.addressId,
      text: this.processAddress(address),
      status: address.status
    }))
  })

  processAddress = (address: AddressDTO) => {
    let txt_address = ''
    if (typeof address.addressLineOne != 'undefined' && address.addressLineOne && address.addressLineOne.trim() != '') {
      txt_address += `${address.addressLineOne.trim()}${address.addressLineOne.trim().endsWith(', ') ? '' : ', '}`
    }
    if (typeof address.addressLineTwo != 'undefined' && address.addressLineTwo && address.addressLineTwo.trim() != '') {
      txt_address += `${address.addressLineTwo.trim()}${address.addressLineTwo.trim().endsWith(', ') ? '' : ', '}`
    }
    if (typeof address.addressLineThree != 'undefined' && address.addressLineThree && address.addressLineThree.trim() != '') {
      txt_address += `${address.addressLineThree.trim()}${address.addressLineThree.trim().endsWith(', ') ? '' : ', '}`
    }
    if (typeof address.city != 'undefined' && address.city.trim() != '') {
      txt_address += `${address.city.trim()}${address.city.trim().endsWith(',') ? '' : ', '}`
    }
    if (typeof address.country != 'undefined' && address.country.trim() != '') {
      txt_address += `${address.country.trim()}${address.country.trim().endsWith('.') ? '' : '.'}`
    }
    return txt_address
  }


  onOptionSelected(id: number) {
    this.isListVisible = false
    this.selectedAddress.emit(id)
    const address = this.options().filter((address) => address.id = this.addressId())
    this.addressText.set(address.length > 0 ? address[0].text : '')
  }

  selectAll(event: MouseEvent): void {
    (event.target as HTMLInputElement).select()
  }

  @HostListener('document:click', ['$event'])
  clickOutsideDropdown(event: any) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isListVisible = false
    }
  }

  handleEscape = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      this.isListVisible = false
    }
  }

}
