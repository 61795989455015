import { HttpClient } from '@angular/common/http'
import {Injectable} from '@angular/core'
import {Router} from '@angular/router'
import {take} from 'rxjs'
import {CachedAPIRequest} from 'src/app/core/classes/cached.api.request.class'
import {SearchProductType} from '../interfaces/product-type'
import {APIRequestResources} from 'src/app/core/enums/api.request.enum'

@Injectable({
  providedIn: 'root'
})
export class ProductTypeService extends CachedAPIRequest {

  constructor(protected override http: HttpClient, private router: Router) {
    super(http, APIRequestResources.ProductTypeService)
  }
  getAll = (refresh = false) => {
    return this.get<SearchProductType>({
      endpoint: `/all`,
    }, refresh ? 'freshness' : 'performance')
  }
  getById = (id: string, refresh = false) => {
    return this.get<SearchProductType>({ id }, refresh ? 'freshness' : 'performance').pipe(take(1))
  }
  getCategory = (id: string, refresh = false) => {
    return this.get<SearchProductType>({ id }, refresh ? 'freshness' : 'performance').pipe(take(1))
  }
}
