import {Component, ElementRef, EventEmitter, HostListener, inject, Output, signal} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {ProductDesignService} from "../../../services/product-design.service";
import {ProductDesignResultDTO} from "../../../interfaces/product-design.entity";

@Component({
  selector: 'app-search-product-design',
  standalone: true,
  imports: [
    FormsModule
  ],
  templateUrl: './search-product-design.component.html',
  styleUrls: ['./search-product-design.component.scss']
})
export class SearchProductDesignComponent {
  productDesignService = inject(ProductDesignService);

  filterValue = signal('');
  isListVisible = false;

  @Output() selectedProductDesign = new EventEmitter<number>();

  get productDesigns(): ProductDesignResultDTO[] {
    return this.productDesignService.all();
  }

  constructor(private elementRef: ElementRef) {}

  onInputChange(value: string): void {
    this.filterValue.set(value);
  }

  onOptionSelected(productDesignId: number): void {
    this.isListVisible = false;
    this.selectedProductDesign.emit(productDesignId);
    this.productDesignService.getById(String(productDesignId), true).subscribe({
      error: (error) => console.error(error)
    });
  }

  selectAll(event: MouseEvent): void {
    (event.target as HTMLInputElement).select();
  }

  @HostListener('document:click', ['$event'])
  clickOutsideDropdown(event: any): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isListVisible = false;
    }
  }
}
