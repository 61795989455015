<div class="flex flex-row pt-4">
  <div class="basis-1/2 text-left my-auto" [innerHTML]="currentPage + ' of ' + totalPages" ></div>
  <div class="pagination basis-1/2">
    <button (click)="previousPages()" [disabled]="startPage === 1" class="pagination-button"><</button>
    @for (pageNumber of visiblePageNumbers; track pageNumber) {
      <button
        (click)="goToPage(pageNumber)"
        [class.active]="pageNumber === currentPage"
        class="pagination-button"
      >
        {{ pageNumber }}
      </button>
    }
    <button (click)="nextPages()" [disabled]="endPage >= totalPages" class="pagination-button">></button>
  </div>
</div>
