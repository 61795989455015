<div class="navbar">
    <img src="../../../../../assets/images/Maxim-side-nav-logo.png" alt="" class="w-36 h-9 mx-10 my-8">
    <ul class="nav-list">
        @for (data of navData; track data) {
            <li class="nav-item">
                <a class="nav-link" [routerLink]="data.routeLink"
                   [class.is-active]="selectedNavItem === data.label"
                   (click)="toggleSubMenu(data.label)">
                    <img [src]="selectedNavItem === data.label ? data.iconActive : data.icon"
                         alt="Nav Icon" class="nav-link-icon">
                    <p class="nav-link-text">{{ data.label }}</p>
                </a>
                @if (selectedNavItem === data.label && subMenuVisible) {
                    <ul class="nested-nav-list">
                    </ul>
                }
            </li>
        }
    </ul>
</div>