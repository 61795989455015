import {Injectable} from '@angular/core'
import { HttpClient } from '@angular/common/http'
import {Router} from '@angular/router'
import {APIRequestResources} from 'src/app/core/enums/api.request.enum'
import {CachedAPIRequest} from 'src/app/core/classes/cached.api.request.class'
import {take} from "rxjs";
import {ProductDesignDTO} from "../../../interfaces/product-design.entity";

@Injectable({
  providedIn: 'root'
})
export class ProductDesignService extends CachedAPIRequest {


  constructor(protected override http: HttpClient, private router: Router) {
    super(http, APIRequestResources.ProductSampleService)
  }

  getById = (id: any, refresh = false) => {
    return this.get<ProductDesignDTO>({ id }, refresh ? 'freshness' : 'performance').pipe(take(1))
  }
}
