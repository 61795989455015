import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core'
import {FormsModule} from '@angular/forms'
import {SearchDepartment} from 'src/app/components/interfaces/department.entity'
import {DepartmentService} from '../../../services'
import {debounceTime, distinctUntilChanged, of, Subject, Subscription, switchMap} from 'rxjs'
import {DropDown} from "../../../../core/types/drop-down.type";
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'app-search-department',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './search-department.component.html',
  styleUrl: './search-department.component.scss'
})
export class SearchDepartmentComponent implements OnInit, OnDestroy, OnChanges {

  private subscription: Subscription = new Subscription()

  searchParams: SearchDepartment = {
    use_for_gpd: '-1',
    department_status: '',
    page_number: "1",
    items_per_page: "100"
  }

  private searchTermsSubject = new Subject<string>()
  searchTerms$ = this.searchTermsSubject.asObservable()

  isListVisible: boolean = false
  options: DropDown[] = []
  departments: DropDown[] = []
  searchTerm: string | undefined
  departmentId: number | undefined

  @Input() departmentInput: number | undefined
  @Input() useForGPD: boolean | undefined
  @Output() selectDepartment = new EventEmitter<number>()

  constructor(private departmentService: DepartmentService, @Inject(DOCUMENT) private document: Document, private elementRef: ElementRef) {
  }

  ngOnInit(): void {

    if (typeof this.useForGPD  != 'undefined') {
      this.searchParams.use_for_gpd = this.useForGPD ? '1' : '0'
    }

    this.subscription.add(
      this.departmentService.find(this.searchParams, true)
        .subscribe({
          next: (res) => {
            this.departments = res.data.data.map(department => ({
              id: department.departmentId,
              text: `${department.departmentName} (${department.departmentCode})`,
              status: department.departmentStatus
            }))
            this.options = [...this.departments]
          },
          error: (error) => console.error(error)
        })
    )

    this.subscription.add(this.searchTerms$.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(term => {
          const filteredResults = this.departments.filter(item =>
            item.text.toLowerCase().startsWith(term.toLowerCase())
          );
          return of(filteredResults);
        }),
      ).subscribe(filteredResults => {
        this.options = [...filteredResults]
      })
    )

    this.document.addEventListener('keydown', this.handleEscape, false)
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes['departmentInput']) {
      if (typeof this.departmentInput != 'undefined' && this.departmentInput > 0) {
        this.onOptionSelected(this.departmentInput)
      }
    }
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe()
    this.document.removeEventListener('keydown', this.handleEscape, false)
  }

  onInputChange(value: string): void {
    this.searchTermsSubject.next(value)
  }

  onOptionSelected(id: number) {
    const department = this.options.filter(option => option.id == id)
    if (department && department.length > 0) {
      this.searchTerm = department[0].text
      this.departmentId = department[0].id
    }
    this.isListVisible = false
    this.selectDepartment.emit(id)
  }

  selectAll(event: MouseEvent): void {
    (event.target as HTMLInputElement).select()
  }

  @HostListener('document:click', ['$event'])
  clickOutsideDropdown(event: any) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isListVisible = false
      if (!this.departmentId) {
        this.searchTerm = ''
        this.options = [...this.departments]
      }
    }
  }

  handleEscape = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      this.isListVisible = false
      if (!this.departmentId) {
        this.searchTerm = ''
        this.options = [...this.departments]
      }
    }
  }

}
