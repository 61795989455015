import {Component} from '@angular/core';

@Component({
  selector: 'app-button-container',
  standalone: true,
  imports: [],
  templateUrl: './button-container.component.html',
  styleUrl: './button-container.component.scss'
})
export class ButtonContainerComponent {
  // @Input()
show(){

}
}
