<div class="searchable-dropdown">
  <input type="text" class="w-full bg-white border border-gray-200 rounded h-10 px-2" placeholder="" [(ngModel)]="addressText"
         (focus)="this.isListVisible = true">
  @if (isListVisible) {
    <ul class="list">
      @for (option of options(); track option) {
        <li class="item" (click)="onOptionSelected(option.id)">
          {{ option.text }}
        </li>
      }
    </ul>
  }
</div>
