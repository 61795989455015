import {Injectable} from '@angular/core'
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject'
import {ProductSampleDTO, SearchProductSampleDTO,} from '../../../interfaces/product-sample.types'
import { HttpClient } from '@angular/common/http'
import {Router} from '@angular/router'
import {APIRequestResources} from 'src/app/core/enums/api.request.enum'
import {CachedAPIRequest} from 'src/app/core/classes/cached.api.request.class'
import {catchError, take, tap} from 'rxjs'
import {PaginationResponse} from 'src/app/core/interfaces'
import {toSignal} from "@angular/core/rxjs-interop";
import {handleError} from "../../../../core/util/util";

@Injectable({
  providedIn: 'root'
})
export class ProductSampleService extends CachedAPIRequest {

  private readonly $all = new BehaviorSubject<SearchProductSampleDTO[]>([]);
  all = toSignal(this.$all, {initialValue: []})

  private readonly $active = new BehaviorSubject<ProductSampleDTO | undefined>(undefined);
  active = toSignal(this.$active, {initialValue: undefined})

  private readonly $statistics = new BehaviorSubject<any>(undefined)
  stat = toSignal(this.$statistics, {initialValue: undefined})


  constructor(protected override http: HttpClient, private router: Router) {
    super(http, APIRequestResources.ProductSampleService)
    this.getAll().pipe(take(1)).subscribe()
  }

  find = (params: any, refresh = false) => {
    return this.get<PaginationResponse<SearchProductSampleDTO[]>>({
      endpoint: `ps/find`,
      params: params
    }, refresh ? 'freshness' : 'performance')
      .pipe(
        tap((res) => this.$all.next(res.data.data)),
        tap((res) => {
          const {data, ...obj} = res.data
          this.$statistics.next(obj)
        })
      )
  }

  getById = (id: string, refresh = false) => {
    return this.get<ProductSampleDTO>({id}, refresh ? 'freshness' : 'performance')
      .pipe(
        tap((res) => this.$active.next(res.data)),
      )
  }

  getAll = (refresh = false) => {
    return this.get<PaginationResponse<SearchProductSampleDTO[]>>({
        endpoint: `ps/find`,
        params: {
          sample_code: '',
          sample_name: '',
          customer_id: '-1',
          buyer_id: '-1',
          department_id: '-1',
          page_number: "1",
          items_per_page: "10"
        }
      },
      refresh ? 'freshness' : 'performance')
      .pipe(
        tap(res => this.$all.next(res.data.data ?? [])),
        catchError(handleError)
      )
  }

  update = (sampleId: any, sampleDetails: any) => {
    const options = {suffix: sampleId};
    return this.patch<any>(sampleDetails, options);
  }
}
