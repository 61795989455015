import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core'
import {FormsModule} from '@angular/forms'
import {SearchBuyer} from "../../../interfaces"
import {BuyerService} from '../../../services'
import {debounceTime, distinctUntilChanged, of, Subject, Subscription, switchMap} from 'rxjs'
import {DropDown} from "../../../../core/types/drop-down.type";
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'app-search-buyer',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './search-buyer.component.html',
  styleUrl: './search-buyer.component.scss'
})
export class SearchBuyerComponent implements OnInit, OnDestroy, OnChanges {

  private subscription: Subscription = new Subscription()

  searchParams: SearchBuyer = {
    buyer_status: '',
    page_number: "1",
    items_per_page: "100"
  }

  private searchTermsSubject = new Subject<string>()
  searchTerms$ = this.searchTermsSubject.asObservable()

  isListVisible: boolean = false
  options: DropDown[] = []
  buyers: DropDown[] = []
  searchTerm: string | undefined
  buyerId: number | undefined

  @Input() buyerInput: number | undefined
  @Output() selectBuyer = new EventEmitter<number>()

  constructor(private buyerService: BuyerService, @Inject(DOCUMENT) private document: Document, private elementRef: ElementRef) {}

  ngOnInit(): void {

    this.subscription.add(
      this.buyerService.find(this.searchParams, true)
        .subscribe({
          next: (res) => {
            this.buyers = res.data.data.map(buyer => ({
              id: buyer.buyerId,
              text: buyer.buyerName,
              status: buyer.buyerStatus
            }))
            this.options = [...this.buyers]
          },
          error: (error) => console.error(error)
        })
    )

    this.subscription.add(this.searchTerms$.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(term => {
          const filteredResults = this.buyers.filter(item =>
            item.text.toLowerCase().startsWith(term.toLowerCase())
          );
          return of(filteredResults);
        }),
      ).subscribe(filteredResults => {
        this.options = [...filteredResults]
      })
    )

    this.document.addEventListener('keydown', this.handleEscape, false)
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes['buyerInput']) {
      if (typeof this.buyerInput != 'undefined' && this.buyerInput > 0) {
        this.onOptionSelected(this.buyerInput)
      }
    }
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe()
    this.document.removeEventListener('keydown', this.handleEscape, false)
  }

  onInputChange(value: string): void {
    this.searchTermsSubject.next(value)
  }

  onOptionSelected(id: number) {
    const buyer = this.options.filter(option => option.id == id)
    if (buyer && buyer.length > 0) {
      this.searchTerm = buyer[0].text
      this.buyerId = buyer[0].id
    }
    this.isListVisible = false
    this.selectBuyer.emit(id)
  }

  selectAll(event: MouseEvent): void {
    (event.target as HTMLInputElement).select()
  }

  @HostListener('document:click', ['$event'])
  clickOutsideDropdown(event: any) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isListVisible = false
      if (!this.buyerId) {
        this.searchTerm = ''
        this.options = [...this.buyers]
      }
    }
  }

  handleEscape = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      this.isListVisible = false
      if (!this.buyerId) {
        this.searchTerm = ''
        this.options = [...this.buyers]
      }
    }
  }

}
