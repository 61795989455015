import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject, tap} from 'rxjs';
import {APIRequestResources, PaginationResponse} from "../../../../core"
import {CachedAPIRequest} from 'src/app/core/classes/cached.api.request.class';
import {SalesOrderItemDTO, SearchSalesOrderItemDTO} from "../interfaces/sales-order-item.entity";
import {toSignal} from "@angular/core/rxjs-interop";

@Injectable({
  providedIn: 'root'
})
export class SalesOrderItemService extends CachedAPIRequest {

  private readonly $all = new BehaviorSubject<SearchSalesOrderItemDTO[]>([]);
  all = toSignal(this.$all, {initialValue: []})

  private readonly $active = new BehaviorSubject<SalesOrderItemDTO | undefined>(undefined);
  active = toSignal(this.$active, {initialValue: undefined})

  private readonly $statistics = new BehaviorSubject<any>(undefined)
  stat = toSignal(this.$statistics, {initialValue: undefined})

  constructor(protected override http: HttpClient, private router: Router) {
    super(http, APIRequestResources.SalesOrderItemService)
  }

  getById = (id: any, refresh = false) => {
    return this.get<SalesOrderItemDTO>({id}, refresh ? 'freshness' : 'performance')
      .pipe(
        tap((res) => this.$active.next(res.data)),
      )
  }

  find = (searchParams: any, refresh = false) => {
    return this.get<PaginationResponse<SearchSalesOrderItemDTO[]>>({
      endpoint: `find`,
      params: searchParams,
    }, refresh ? 'freshness' : 'performance')
      .pipe(
        tap((res) => this.$all.next(res.data.data)),
        tap((res) => {
          const {data, ...obj} = res.data
          this.$statistics.next(obj)
        })
      )
  }

  update = (salesOrderItemId: number, salesOrderItemDetail: any) => {
    const options = { suffix: salesOrderItemId.toString() };
    return this.patch<any>(salesOrderItemDetail, options);

  }

  create = (salesOrderItem:any) => {
    return this.post<any>(salesOrderItem, {})
  }

  initial  = () => {
    this.$active.next(undefined)
    // this.$all.next([])
  }
}
