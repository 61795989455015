<div class="searchable-dropdown">
  <input type="text" class="form-input" placeholder="Buyer" [(ngModel)]="searchTerm"
    (ngModelChange)="onInputChange($event)" (focus)="this.isListVisible = true" (click)="selectAll($event)" />
  @if (isListVisible) {
  @if (isListVisible) {
  <ul class="list">
    @for (option of options; track option) {
    <li class="item" (click)="onOptionSelected(option.id)">
      {{ option.text }}
    </li>
    }
  </ul>
  }
  }
</div>
