<form action="">
  <div class="mx-auto bg-white rounded-lg overflow-hidden">
    <div class="px-5 pt-5 pb-40">
      <form class="flex flex-wrap -mx-3">
        <div class="w-full md:w px-3 mb-6">
          <label class="form-label">Customer</label>
          <app-search-customer>
          </app-search-customer>
        </div>
        <div class="w-full md:w-1/4 px-3 mb-6">
          <label class="form-label" for="poNumber">Purchase Order No</label>
          <input [(ngModel)]="searchSalesOrder.po_number" class="form-input" id="poNumber" type="text">
        </div>
        <div class="w-full md:w-1/2 px-3 mb-6">
          <label class="block mb-2 text-sm font-medium text-gray-900" for="salesOrderDate">Purchase Order Date</label>
          <input [(ngModel)]="searchSalesOrder.sales_order_date" class="form-input" id="salesOrderDate" name="salesOrderDate"
                 type="date">
        </div>
        <div class="w-full md:w-1/2 px-3 mb-6">
          <label class="block mb-2 text-sm font-medium text-gray-900" for="salesOrderReference">Sales Order
            Reference</label>
          <input [(ngModel)]="searchSalesOrder.sales_order_reference" class="form-input" id="salesOrderReference" name="salesOrderReference"
                 type="text">
        </div>
        <div class="w-full px-3 text-right">
          <button class="text-white bg-blue-500 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full md:w-auto px-5 py-2.5 text-center"
                  type="submit">
            Submit
          </button>
        </div>
      </form>
    </div>
  </div>
</form>
