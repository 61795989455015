import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {TablePaginationComponent} from "../../../../shared/common/table-pagination/table-pagination.component";
import {ProductSampleService} from 'src/app/components/services';
import {SearchProductSampleDTO} from 'src/app/components/interfaces/product-sample.types';

import {FormsModule} from '@angular/forms';

@Component({
  selector: 'app-product-sample-search-result',
  standalone: true,
  templateUrl: './product-development-search-result.component.html',
  styleUrl: './product-development-search-result.component.scss',
  imports: [TablePaginationComponent, FormsModule]
})
export class ProductDevelopmentSearchResultComponent implements OnInit {
  constructor(private productSampleService: ProductSampleService) { }
  @Output() selectedSampleId = new EventEmitter<number>();

  productSample: SearchProductSampleDTO[] = []
  sampleId = -1

  ngOnInit(): void {
    this.productSampleService.find(true).subscribe({
      next: (res) => {
        this.productSample = res.data.data
        console.log(res.data.data)
      }
    })
  }

  handleSampleClick(sampleId:any){
    this.sampleId=sampleId

  }
  sub(){
    this.selectedSampleId.emit(this.sampleId)
  }
}
