@if (totalItems > 0) {
    <div class="grid grid-cols-1 md:grid-cols-12 gap-4 px-8 py-4 rounded-xl min-w-max">
        <div class="bg-white rounded-lg shadow-lg md:col-span-4 lg:col-span-12">
            <div class="p-6">
                <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead class="text-xs text-gray-700 bg-gray-50">
                    <tr>
                        <th scope="col" class="p-4">
                        </th>
                        @if(searchParams.sales_order_id == '-1') {
                            <th scope="col" class="px-6 py-3">
                                Sales Order Number
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Customer
                            </th>
                        }
                        <th scope="col" class="px-6 py-3">
                            Plan Card No
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Sample Code
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Sample Name
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Description
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Design Code
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Unit Price
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Quantity
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                        @for (salesOrderItem of salesOrderItems; track salesOrderItem) {
                            <tr class="bg-white border-b hover:bg-gray-50 cursor-pointer"
                                (click)="handleSalesOrderItemClick(salesOrderItem.salesOrderItemId)">
                                <td class="w-4 p-4">
<!--                                    <div class="flex items-center">-->
<!--                                        <input id="checkbox-table-search-2" name="salesOrderItemId" type="radio" [value]="salesOrderItem.salesOrderItemId" [(ngModel)]="selectedSalesOrderItemId"-->
<!--                                               class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">-->
<!--                                        <label for="checkbox-table-search-2" class="sr-only">checkbox</label>-->
<!--                                    </div>-->
                                </td>
                                @if(searchParams.sales_order_id == '-1') {
                                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-gray-900">
                                        {{ salesOrderItem.salesOrderReference }}
                                    </th>
                                    <td class="px-6 py-4">
                                        {{ salesOrderItem.customerName }}
                                    </td>
                                }
                                <td class="px-6 py-4">
                                    {{ salesOrderItem.planCardNo }}
                                </td>
                                <td class="px-6 py-4">
                                    {{ salesOrderItem.productSampleCode }}
                                </td>
                                <td class="px-6 py-4">
                                    {{ salesOrderItem.productSampleName }}
                                </td>
                                <td class="px-6 py-4">
                                    {{ salesOrderItem.description }}
                                </td>
                                <td class="px-6 py-4">
                                    {{ salesOrderItem.productDesignCode }}
                                </td>
                                <td class="px-6 py-4">
                                    {{ salesOrderItem.unitPrice }}
                                </td>
                                <td class="px-6 py-4">
                                    {{ salesOrderItem.quantity }}
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
                <app-table-pagination [totalItems]="totalItems" [itemsPerPage]="itemsPerPage"
                                      (pageChanged)="onPageChange($event)"></app-table-pagination>
            </div>
        </div>
    </div>
}

