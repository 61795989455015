import {Component} from '@angular/core';
import {NgOptimizedImage} from "@angular/common";
import {AuthService} from "../../../../core";

@Component({
  selector: 'app-user-profile',
  standalone: true,
    imports: [
        NgOptimizedImage
    ],
  templateUrl: './user-profile.component.html',
  styleUrl: './user-profile.component.scss'
})
export class UserProfileComponent {
  greeting: string = '';
  user: string = ''

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.setGreetingBasedOnTime();
    const userData = sessionStorage.getItem('user-auth');
    if(!!userData?.trim()) {
      this.user = JSON.parse(userData).name
    }
  }

  setGreetingBasedOnTime(): void {
    const hour = new Date().getHours();

    if (hour < 12) {
      this.greeting = 'Good Morning';
    } else if (hour < 18) {
      this.greeting = 'Good Afternoon';
    } else {
      this.greeting = 'Good Evening';
    }
  }

  logOut() {
    this.authService.userSignOut();
  }
}
