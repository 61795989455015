<div class="main-container">
    <div class="card1">
      <div>
        <span class="text">Sample Name</span><br>
        <app-search-sample-number [displayField]="'sampleName'" [filterKey]="'sample_name'"></app-search-sample-number>
      </div>
      <div>
        <span class="text">Sample Code</span><br>
        <app-search-sample-number [displayField]="'sampleCode'" [filterKey]="'sample_code'"></app-search-sample-number>
      </div>
      <div>
        <span class="text">Description</span><br>
        <input type="text" class="form-input" [(ngModel)]="salesOrderItem.description" name="description">
      </div>
      <div>
        <span class="text">Customer Ref</span><br>
        <input type="text" class="form-input" [(ngModel)]="salesOrderItem.customerReference" name="customerReference">
      </div>
      <div>
        <span class="text">Artwork Ref</span><br>
        <textarea class="form-input" [(ngModel)]="salesOrderItem.artworkReference" name="artworkReference"></textarea>
      </div>
      <div>
        <span class="text">Remarks</span><br>
        <input type="text" class="form-input" placeholder="Remark Here" [(ngModel)]="salesOrderItem.remarks" name="remarks">
      </div>
    </div>
    <div class="card2">
      <div>
        <span class="text">Design No</span><br>
        <input type="text" class="form-input" [(ngModel)]="updateSalesOrderItem.productDesignCode" name="productDesignCode">
      </div>
      <div>
        <span class="text">Style No</span><br>
        <input type="text" class="form-input" [(ngModel)]="salesOrderItem.styleNo" name="styleNo">
      </div>
      <div>
        <span class="text">Unit</span><br>
        <app-search-unit [(ngModel)]="salesOrderItem.unitId" name="unitId"></app-search-unit>
      </div>
      <div>
        <span class="text">Quantity</span><br>
        <input type="text" class="form-input" [(ngModel)]="salesOrderItem.quantity" name="quantity">
      </div>
      <div>
        <span class="text">Customer Req Date</span><br>
        <input type="date" class="form-input" [(ngModel)]="salesOrderItem.customerRequestDate" name="customerRequestDate">
      </div>
      <div>
        <span class="text">Delivery Req Address</span><br>
        <app-search-address [addressId]="salesOrderItem.deliveryAddressId" [addresses]="deliveryAddresses"></app-search-address>
      </div>
      <div class="grid grid-cols-3 col-span-2 gap-8 mt-3">
        <div>
          <button class="button" type="submit" (click)="submit()">Update</button>
        </div>
        <div>
          <button class="button" type="button" >Cancel</button>
        </div>
        <div>
          <button class="button" type="button" >Clear</button>
        </div>
      </div>
    </div>
    <div class="card4">
      <div>
        <span class="text">Value</span><br>
        <input type="text" class="form-input" placeholder="Value" name="value">
      </div>
      <div>
        <span class="text">Total Quantity</span><br>
        <input type="text" class="form-input" placeholder="Total Quantity" name="totalQuantity">
      </div>
      <div class="mt-5">
        <button class="button" type="button">Sales Order Item</button>
      </div>
      <div class="mt-5">
        <button class="button" type="button">Order Breakdown</button>
      </div>
    </div>

</div>
