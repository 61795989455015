<div class="searchable-dropdown">
  <input type="text" class="form-input" placeholder="Select Customer" [(ngModel)]="searchTerm"
    (focus)="this.isListVisible = true"/>
  @if (isListVisible) {
  @if (isListVisible) {
  <ul class="list">
    @for (option of hsCode; track option) {
    <li class="item" (click)="onOptionSelected(option.id)">
      {{ option.text }}
    </li>
    }
  </ul>
  }
  }
</div>
