import { HttpClient } from '@angular/common/http'
import {Injectable} from '@angular/core'
import {Router} from '@angular/router'
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject'
import {CachedAPIRequest} from 'src/app/core/classes/cached.api.request.class'
import {APIRequestResources} from 'src/app/core/enums/api.request.enum'
import {EmployeeDTO} from '../interfaces/employee.entity'
import {catchError, take, tap} from 'rxjs'
import {toSignal} from "@angular/core/rxjs-interop";
import {handleError} from "../../core/util/util";

@Injectable({
  providedIn: 'root'
})
export class EmployeeService extends CachedAPIRequest {

  private readonly $all = new BehaviorSubject<EmployeeDTO[]>([])
  all = toSignal(this.$all, {initialValue: []})


  constructor(protected override http: HttpClient, private router: Router) {
    super(http, APIRequestResources.EmployeeService)
    this.getAll().pipe(take(1)).subscribe()
  }


  getAll(refresh = false) {
    return this.get<EmployeeDTO[]>({}, refresh ? 'freshness' : 'performance')
      .pipe(
        tap(res => this.$all.next(res.data?? [])),
        catchError(handleError)
      )
  }

}
