import {Component, OnInit} from '@angular/core';
import {
    UpdateProductDevelopmentFormComponent
} from "../../shared/update-product-development-form/update-product-development-form.component";
import {NgOptimizedImage} from '@angular/common';
import {
    ProductDevelopmentSearchResultComponent
} from "../../shared/product-development-search-result/product-development-search-result.component";
import {SearchDepartmentComponent} from "../../../../shared/common/search-department/search-department.component";
import {UserProfileComponent} from "../../../../shared/common/user-profile/user-profile.component";
import {SearchProductTypeComponent} from "../../../../shared/common/search-product-type/search-product-type.component";

@Component({
  selector: 'app-product-sample',
  standalone: true,
  templateUrl: './product-sample.component.html',
  styleUrl: './product-sample.component.scss',
  imports: [
    UpdateProductDevelopmentFormComponent,
    NgOptimizedImage,
    ProductDevelopmentSearchResultComponent,
    SearchDepartmentComponent,
    UserProfileComponent,
    SearchProductTypeComponent
  ]
})
export class ProductSampleComponent implements OnInit {
  ngOnInit(): void {
    //  this.handleSampleId(this.sampleId)
  }
  sampleId = -1
  typeId = -1

  handleSampleId(sampleId: any) {
    this.sampleId = sampleId
    // console.log(this.sampleId)
  }
  handleTypeIdclick(typeId: any) {
    this.typeId = typeId
  }
}
