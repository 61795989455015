<div class="card-main-container">
    <div class="col-span-1 sub-container">
        <div>
            <app-search-customer></app-search-customer>
        </div>
    </div>
    <div class="grid grid-cols-3 col-span-2 sub-container">
        <div class="form-group w-full">
            <select [ngModel]="searchOption()" (ngModelChange)="setSearchOption($event)" class="input-new">
                <option value="po_number">Customer PO</option>
                <option value="sales_order_reference">Sales Order Reference</option>
                <option value="sales_order_date">Sales Order Date</option>
            </select>
        </div>
        <div class="form-group w-full">
            @if (searchOption() === 'sales_order_date') {
                <input [ngModel]="searchValue()" (ngModelChange)="setSearchValue($event)"
                       [placeholder]="getPlaceholder()" class="input-new"
                       name="searchValue" type="date">
            } @else {
                <input [ngModel]="searchValue()" (ngModelChange)="setSearchValue($event)"
                       (input)="onSearchInput($event)" [placeholder]="getPlaceholder()"
                       class="input-new" id="searchValue" name="searchValue" type="search">
            }
        </div>
        <div class="grid grid-cols-2 gap-x-4">
            <button (click)="searchSalesOrder()" class="button-new">
                Search
            </button>
            @if (customer) {
                <button (click)="showCreateForm()" class="button-new">
                    New Order
                </button>
            } @else {
                <button disabled class="disabled">New Order</button>
            }
        </div>
        <div>
        </div>
    </div>
    <div class="col-span-1 sub-container">
        <app-user-profile></app-user-profile>
    </div>
</div>

@switch (currentView) {
    @case ('search') {
        <div>
            <app-sales-order-grid [defaultSearchParams]="searchSalesOrderParams">
            </app-sales-order-grid>
        </div>
    }
    @case ('create') {
        <div>
            <app-sales-order-form></app-sales-order-form>
        </div>
    }
    @case ('update') {
        <div>
            <app-sales-order-form></app-sales-order-form>
        </div>
    }
}

@if (currentView == 'search') {
    @if (salesOrder) {
        <div class="main-container">
            <div class="col-span-4 sub-container grid grid-cols-4">
                <div class="py-2">
                    <button (click)="showUpdateForm()" class="button-new">Update Order</button>
                </div>
<!--                <div class="py-2">-->
<!--                    <button (click)="deliverConfirmationView()" class="button-new">Delivery Confirmation</button>-->
<!--                </div>-->
                @if (salesOrder.salesOrderItemCount > 0) {
                    <div class="py-2">
                        <button (click)="showViewForm()" class="button-new">View Items</button>
                    </div>
                } @else {
                    <div class="py-2">
                        <button disabled class="disabled">View Items</button>
                    </div>
                }
                @if (salesOrder.salesOrderItemCount > 0) {
                    <div class="py-2">
                        <button disabled class="disabled">Download Items</button>
                    </div>
                } @else {
                    <div class="py-2">
                        <button (click)="orderDownloadView()" class="button-new">Download Items</button>
                    </div>
                }
            </div>
        </div>
    } @else {
        <div class="main-container">
            <div class="col-span-3 sub-container grid grid-cols-3">
                <div class="py-2">
                    <button disabled class="disabled">Update Order</button>
                </div>
                <div class="py-2">
                    <button disabled class="disabled">View Items</button>
                </div>
                <div class="py-2">
                    <button disabled class="disabled">Download Items</button>
                </div>
            </div>
        </div>
    }
}