<div class="app-top-nav">

    <header class="header">Maxim ERP</header>

    <div class="search">
        <fa-icon [icon]="faMagnifyingGlass" class=" text-slate-500 pr-4"></fa-icon>
        <input class="input" type="search">

    </div>
    <div class="icons text-slate-500 float-right">

        <fa-icon [icon]="faBell" class="bell pl-4 "></fa-icon>
        <fa-icon [icon]="faComment" class="bell pl-4 "></fa-icon>
        <fa-icon [icon]="faUser" class="bell pl-4 "></fa-icon>

    </div>


</div>
