import {Injectable} from '@angular/core'
import { HttpClient } from "@angular/common/http"
import {Router} from "@angular/router"
import {APIRequestResources, APIResponse, PaginationResponse} from "../../core"
import {CachedAPIRequest} from "../../core/classes/cached.api.request.class"
import {BehaviorSubject, catchError, take, tap} from "rxjs"
import {CreateCustomer, CurrencyDTO, CustomerDTO} from "../interfaces"
import {toSignal} from "@angular/core/rxjs-interop";
import {handleError} from "../../core/util/util";

@Injectable({
  providedIn: 'root'
})

export class CustomerService extends CachedAPIRequest {

  private readonly $all = new BehaviorSubject<CustomerDTO[]>([])
  all = toSignal(this.$all, {initialValue: []})

  private readonly $active = new BehaviorSubject<CustomerDTO | undefined>(undefined)
  active = toSignal(this.$active, {initialValue: undefined})

  constructor(protected override http: HttpClient, private router: Router) {
    super(http, APIRequestResources.CustomerService)
    this.getAll().pipe(take(1)).subscribe()
  }


  getById = (id: string, refresh = false) => {
    return this.get<CustomerDTO>({id}, refresh ? 'freshness' : 'performance')
      .pipe(
        tap((res) => this.$active.next(res.data)),
        catchError(handleError)
      )

  }

  getAll = (refresh = false) => {
    return this.get<PaginationResponse<CustomerDTO[]>>({
        endpoint: `find`,
        params: {
          customer_name: '',
          payment_terms: '',
          currency_id: '-1',
          invoice_type_id: '-1',
          customer_status: '',
          page_number: "1",
          items_per_page: "10"
        }
      },
      refresh ? 'freshness' : 'performance')
      .pipe(
        tap(res => this.$all.next(res.data.data ?? [])),
        catchError(handleError)
      )
  }

  find = (params: any, refresh = false) => {
    return this.get<PaginationResponse<CustomerDTO[]>>({
        endpoint: `find`,
        params: params
      },
      refresh ? 'freshness' : 'performance')
      .pipe(
        tap(res => this.$all.next(res.data.data ?? [])),
        catchError(handleError)
      )
  }

  create = (customer: CreateCustomer) => {
    return this.post<APIResponse<CurrencyDTO>>(customer, {})
  }

  // update = (id: string, customer: Upda) => {
  //   return this.patch<CustomerDTO>(buyer,{ id }).pipe(
  //     tap(() => this.getById(id))
  //   )
  // }

  // private handleError(err: HttpErrorResponse): Observable<never> {
  //   let errorMessage = '';
  //   if (err.error instanceof ErrorEvent) {
  //     errorMessage = `An error occurred: ${err.error.message}`;
  //   } else {
  //     errorMessage = `Server returned code: ${err.status}, error message is: ${err.message
  //     }`;
  //   }
  //   console.error(errorMessage);
  //   return throwError(() => errorMessage);
  //
  // }

}
