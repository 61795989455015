<div class="main-container">
  <div class="col-span-1 sub-container">
    <button class="button hover:bg-[#FFDAEF]" (click)="show()"> Order Breakdown</button>
  </div>
  <div class="col-span-3 sub-container grid grid-cols-3">
    <div>
      <button class="button hover:bg-[#FFDAEF]">Add Item</button>
    </div>
    <div>
      <button class="button hover:bg-[#FFDAEF]">Update Items</button>
    </div>
    <div>
      <button class="button hover:bg-[#FFDAEF]">View Items</button>
    </div>
  </div>
  <div class="col-span-2 sub-container grid grid-cols-2">
    <div>
      <button class="button hover:bg-[#FFDAEF]">New Order</button>
    </div>
    <div>
      <button class="button hover:bg-[#FFDAEF]">Update Order</button>
    </div>
  </div>
</div>
