<div class="searchable-dropdown">
  <input (click)="selectAll($event)" (focus)="this.isListVisible = true" (ngModelChange)="onInputChange($event)"
         [ngModel]="filterValue()"
         class="form-input"
         placeholder="Select Sample" type="text"/>
  @if (isListVisible) {
    @if (isListVisible) {
      <ul class="list">
        @for (option of productSamples; track option) {
          <li class="item" (click)="onOptionSelected(option.id)">
            {{ option.sampleName }}
          </li>
        }
      </ul>
    }
  }
</div>
