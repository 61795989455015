
<div class="searchable-dropdown">
  <input type="text" class="form-input" placeholder="" [(ngModel)]="filterValue"
         (ngModelChange)="onInputChange($event)" (focus)="this.isListVisible = true" (click)="selectAll($event)" />
  @if (isListVisible) {
    @if (isListVisible) {
      <ul class="list">
        @for (option of productDesigns; track option) {
          <li class="item" (click)="onOptionSelected(option.productDesignId)">
            {{ option.productDesignCode }}
          </li>
        }
      </ul>
    }
  }
</div>
