import {NgOptimizedImage} from '@angular/common';
import {Component, inject} from '@angular/core';
import {SalesOrderService} from "../../../services";

@Component({
  selector: 'app-sales-order-item-head',
  standalone: true,
  imports: [NgOptimizedImage],
  templateUrl: './sales-order-item-head.component.html',
  styleUrl: './sales-order-item-head.component.scss'
})
export class SalesOrderItemHeadComponent {

  salesOrderService: SalesOrderService = inject(SalesOrderService);

  get salesOrder () {
    return this.salesOrderService.active()
  }

}
