import {Component, effect, inject, signal} from '@angular/core';
import {
  SearchAddressComponent,
  SearchCurrencyComponent,
  SearchEmployeeComponent,
  SearchHsCodeComponent,
  SearchProductDesignComponent,
  SearchSampleNumberComponent,
  SearchUnitComponent
} from "../../../../../shared";
import {FormsModule} from "@angular/forms";
import {ProductSampleService} from "../../../../product-development";
import {SalesOrderItem} from "../../../interfaces/sales-order-item.entity";
import {AddressDTO} from "../../../../../interfaces";
import {CustomerService, SalesOrderService, UnitService} from "../../../../../services";
import {SalesOrderItemService} from "../../../services";


@Component({
  selector: 'app-sales-order-item-form',
  standalone: true,
  templateUrl: './sales-order-item-form.component.html',
  styleUrl: './sales-order-item-form.component.scss',
  imports: [SearchHsCodeComponent, FormsModule, SearchCurrencyComponent, SearchSampleNumberComponent, SearchAddressComponent, SearchProductDesignComponent, SearchEmployeeComponent, SearchUnitComponent]
})
export class SalesOrderItemFormComponent {

  salesOrderItemService = inject(SalesOrderItemService)
  salesOrderService = inject(SalesOrderService)
  productSampleService = inject(ProductSampleService)
  customerService = inject(CustomerService)
  unitService = inject(UnitService)

  sampleId = signal('');
  salesOrderItemId = signal<number>(-1)
  customerId = signal<number>(-1)
  unitId = signal<number>(-1)


  salesOrderItem: SalesOrderItem = {
    salesOrderId: -1,
    productDesignId: -1,
    description: '',
    styleNo: '',
    planCardNo: '',
    customerReference: '',
    artworkReference: '',
    unitId: -1,
    cost: 0,
    unitPrice: 0,
    quantity: 0,
    customerRequestDate: '',
    deliveryAddressId: -1,
    remarks: '',
    salesOrderItemStatus: '',
  };

  updateSalesOrderItem = {
    salesOrderId: -1,
    productDesignId: '',
    productDesignCode: '',
    description: '',
    styleNo: '',
    planCardNo: '',
    customerReference: '',
    artworkReference: '',
    unitId: -1,
    cost: 0,
    unitPrice: 0,
    quantity: 0,
    customerRequestDate: '',
    deliveryAddressId: -1,
    remarks: '',
    sampleCode: '',
    sampleName: ''
  }

  deliveryAddresses: AddressDTO[] = [];

  constructor() {
    effect(() => {
      const salesOrder = this.salesOrderService.active();
      if (salesOrder) {
        this.salesOrderItem.salesOrderId=salesOrder.salesOrderId
        this.customerId.set(salesOrder.customer.customerId);
        this.salesOrderItem.deliveryAddressId = <number>salesOrder.deliveryAddress?.addressId;
        this.getCustomer(this.customerId())
      }
    }, { allowSignalWrites: true });

    effect(() => {
      const salesOrderItem = this.salesOrderItemService.active();
      if (salesOrderItem) {
        this.salesOrderItemId.set(salesOrderItem.salesOrderItemId);
        this.customerId.set(salesOrderItem.salesOrder.customer.customerId);
        this.salesOrderItem.deliveryAddressId = <number>salesOrderItem.deliveryRequestAddress?.addressId;
        this.getCustomer(this.customerId())
      }
    }, { allowSignalWrites: true });

    effect(() => {
      const customer = this.customerService.active();
      if (customer) {
        this.deliveryAddresses = customer.addresses.filter((item: AddressDTO) => {
          if (item.isDefaultDelivery) {
            this.salesOrderItem.deliveryAddressId = item.addressId;
          }
          return item.useForDelivery;
        });
      }
    }, { allowSignalWrites: true });

    effect(() => {
      const productSample = this.productSampleService.active();
      if (productSample) {
        this.updateSalesOrderItem.sampleCode = productSample.productSampleCode;
        this.updateSalesOrderItem.sampleName = productSample.productSampleName;
        this.updateSalesOrderItem.productDesignCode = productSample.productDesign.productDesignCode;
        this.salesOrderItem.productDesignId = productSample.productDesign.productDesignId;
      }
    }, { allowSignalWrites: true });

    effect(() => {
      const units = this.unitService.active();
      if (units) {
        // console.log(units);
        this.unitId.set(units.unitId);
      }
    }, { allowSignalWrites: true });
  }

  getCustomer = (customerId: any) => {
    this.customerService.getById(customerId, false)
      .subscribe({
        error: (error) => {
          console.error('Error:', error);
        },
      });
  }

  getUnit = (unitId: any) => {
    this.unitService.getById(unitId, false).subscribe({
      error: (error) => {
        console.error('Error:', error);
      }
    });
  }

  submit() {
    const submissionObject = {
      sales_order_id: this.salesOrderItem.salesOrderId,
      item_description: this.salesOrderItem.description,
      customer_reference: this.salesOrderItem.customerReference,
      art_work_reference: this.salesOrderItem.artworkReference,
      item_remarks: this.salesOrderItem.remarks,
      style_no: this.salesOrderItem.styleNo,
      item_quantity: this.salesOrderItem.quantity,
      unit_id: this.salesOrderItem.unitId,
      product_design_id: this.salesOrderItem.productDesignId,
      customer_request_date: this.salesOrderItem.customerRequestDate,
      delivery_request_address: this.salesOrderItem.deliveryAddressId
    };

    // console.log(submissionObject);

  }
}
