import {Component} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {SearchSalesOrder} from "../../../interfaces/sales-order.entity";
import {SearchAddressComponent} from "../../../../../shared";
import {SearchCurrencyComponent} from "../../../../../shared/common/search-currency/search-currency.component";
import {SearchCustomerComponent} from "../../../../../shared/common/search-customer/search-customer.component";
import {SearchEmployeeComponent} from "../../../../../shared/common/search-employee/search-employee.component";

@Component({
  selector: 'app-search-sales-order-form',
  standalone: true,
    imports: [
        FormsModule,
        SearchAddressComponent,
        SearchCurrencyComponent,
        SearchCustomerComponent,
        SearchEmployeeComponent
    ],
  templateUrl: './search-sales-order-form.component.html',
  styleUrl: './search-sales-order-form.component.scss'
})
export class SearchSalesOrderFormComponent {

  searchSalesOrder: SearchSalesOrder = {
    sales_order_date: '',
    sales_order_reference: '',
    po_number: '',
    customer_id: '-1',
    items_per_page: '0',
    page_number: '10'
  }

  protected readonly String = String;
}
