<div class="flex items-center justify-between pl-5">
    <div class="flex items-center">
        <div>
            <img ngSrc="assets/icons/profile.svg" class="h-8 w-8 rounded-full mr-2" alt="Profile Icon" height="39" width="30">
        </div>
        <div>
            <p class="text-sm text-gray-800 pl-5">{{ greeting }}</p>
            <p class="text-m font-medium pl-5">{{ user }}</p>
        </div>
    </div>
    <div class="pr-10">
        <img src="../../../../../assets/icons/line/logout.svg" class="h-7 w-7 cursor-pointer " alt="Logout Icon" (click)="logOut()" >
    </div>
</div>