import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, catchError, tap} from 'rxjs';
import {CachedAPIRequest} from 'src/app/core/classes/cached.api.request.class';
import {APIRequestResources} from 'src/app/core/enums/api.request.enum';
import {toSignal} from "@angular/core/rxjs-interop";
import {handleError} from "../../core/util/util";
import {ProductDesignDTO, ProductDesignResultDTO} from "../interfaces/product-design.entity";
import {PaginationResponse} from "../../core";

@Injectable({
  providedIn: 'root'
})
export class ProductDesignService extends CachedAPIRequest {
  private readonly $all = new BehaviorSubject<ProductDesignResultDTO[]>([]);
  all = toSignal(this.$all, { initialValue: [] });

  private readonly $active = new BehaviorSubject<ProductDesignDTO | undefined>(undefined);
  active = toSignal(this.$active, { initialValue: undefined });

  constructor(protected override http: HttpClient) {
    super(http, APIRequestResources.ProductDesignService);
    // this.find({}, true).subscribe();
  }

  getById = (id: string, refresh = false) => {
    return this.get<ProductDesignDTO>({ id }, refresh ? 'freshness' : 'performance')
      .pipe(
        tap((res) => this.$active.next(res.data)),
        catchError(handleError)
      );
  }

  find = (params: any, refresh = false) => {
    return this.get<PaginationResponse<ProductDesignResultDTO[]>>({
      endpoint: `find`,
      params: params
    }, refresh ? 'freshness' : 'performance')
      .pipe(
        tap(res => this.$all.next(res.data.data ?? [])),
        catchError(handleError)
      );
  }
}
