export const navbarData = [
  {
    routeLink: 'home',
    icon: 'assets/icons/line/home.svg',
    iconActive: 'assets/icons/filled/home.svg',
    label: 'HOME',

  },
  {
    routeLink: 'sales-order',
    icon: 'assets/icons/line/sales-order.svg',
    iconActive: 'assets/icons/filled/sales-order.svg',
    label: 'SALES ORDER',

  },
  {
    routeLink: 'packing-list',
    icon: 'assets/icons/line/packaging.svg',
    iconActive: 'assets/icons/filled/packaging.svg',
    label: 'PACKAGING',

  },
  {
    routeLink: 'invoice-view',
    icon: 'assets/icons/line/invoice.svg',
    iconActive: 'assets/icons/filled/invoice.svg',
    label: 'INVOICE',

  },
  {
    routeLink: 'reports',
    icon: 'assets/icons/line/reports.svg',
    iconActive: 'assets/icons/filled/reports.svg',
    label: 'REPORTS',
  },
]
