<div class="searchable-dropdown">
  <input (click)="selectAll($event)" (focus)="this.isListVisible = true" (ngModelChange)="onInputChange($event)"
         [ngModel]="filterValue()"
         class="input-new"
         placeholder="Select Customer" type="search"/>
  @if (isListVisible) {
    @if (isListVisible) {
      <ul class="list">
        @for (option of customers; track option) {
          <li class="item" (click)="onOptionSelected(option.customerId)">
            {{ option.customerName }}
          </li>
        }
      </ul>
    }
  }
</div>
